<template>
  <v-container fluid>
    <h2>Lista DDT</h2>
    <br />
    <v-row>
      <v-col>
        <v-text-field
          label="Matricola"
          outlined
          dense
          hide-details
          v-model="matricola"
          clearable
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="N° DDT"
          outlined
          dense
          hide-details
          v-model="documentNo"
          clearable
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="N° Ordine"
          outlined
          dense
          hide-details
          v-model="orderDocumentNo"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          :items="bpartners"
          v-model="c_bpartner_id"
          label="Cliente"
          hide-no-data
          hide-selected
          item-text="name"
          item-value="c_bpartner_id"
          :search-input.sync="bpSearch"
          outlined
          dense
          clearable
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col>
        <date-picker-text
          label="Data Da"
          outlined
          dense
          hide-details
          v-model="dateFrom"
        ></date-picker-text>
      </v-col>
      <v-col>
        <date-picker-text
          label="Data A"
          outlined
          dense
          hide-details
          v-model="dateTo"
        ></date-picker-text>
      </v-col>
    </v-row>
    <br />
    <v-flex d-flex>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="getShipments()">Cerca</v-btn>
    </v-flex>
    <br />
    <v-data-table :items="shipments" :headers="headers" dense>
      <template v-slot:item.movementDate="{ item }">
        {{ dateToItalianFormat(item.movementDate) }}
      </template>
      <template v-slot:item.dataDDTFornitore="{ item }">
        <span v-if="item.dataDDTFornitore">
          {{ dateToItalianFormat(item.dataDDTFornitore) }}
        </span>
      </template>
      <template v-slot:item.download="{ item }">
        <v-btn icon @click="downloadShipment(item)">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import mixin from "../mixin/MixingCommonComp";
import DatePickerText from "../components/DatePickerText.vue";

export default {
  mixins: [mixin],
  components: { DatePickerText },
  data() {
    return {
      bpSearch: null,
      bpartners: [],

      matricola: null,
      documentNo: null,
      orderDocumentNo: null,
      c_bpartner_id: 0,
      dateFrom: "",
      dateTo: "",

      shipments: [],

      headers: [
        { text: "N° DDT", value: "documentNo" },
        { text: "Data DDT", value: "movementDate" },
        { text: "Cliente", value: "bpname" },
        { text: "Matricola", value: "matricola" },
        { text: "N° Ordine", value: "orderDocumentNo" },
        { text: "N° Entrata Merce", value: "numDDTFornitore" },
        { text: "Data Entrata Merce", value: "dataDDTFornitore" },
        { text: "Scarica", value: "download" },
      ],
    };
  },
  methods: {
    getShipments() {
      this.showLoadingDialog(true, "Caricamento in corso...");
      let parameters = {};
      parameters.data = {
        matricola: this.matricola,
        documentNo: this.documentNo,
        orderDocumentNo: this.orderDocumentNo,
        c_bpartner_id: this.c_bpartner_id,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
      parameters.idempiereRestPath = "seritec/getShipments";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.shipments = response.data.shipments;
          this.showLoadingDialog(false);
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    downloadShipment(item) {
      this.showLoadingDialog(true, "Download in corso...");
      let parameters = {};
      parameters.data = {
        nomeStampa: "Stampa DDT",
        parameters: JSON.stringify({
          RECORD_ID: item.m_inout_id,
        }),
      };
      parameters.idempiereRestPath = "print/addPrintJob";

      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.hasOwnProperty("file")) {
          let linkSource = "data:application/pdf;base64," + response.data.file;
          let downloadLink = document.createElement("a");
          let fileName = item.orderDocumentNo + "_" + item.documentNo + ".pdf";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.showLoadingDialog(false);
        } else {
          var errorMsg = "Download non riuscito";
          if (response.data.hasOwnProperty("message")) {
            errorMsg = errorMsg + "\n" + response.data.message;
          }
          this.showErrorDialog(errorMsg);
        }
      });
    },
  },
  mounted() {
    let date = new Date();
    date.setMonth(date.getMonth() - 3);
    this.dateFrom = date.toISOString().substring(0, 10);
  },
  watch: {
    bpSearch: function () {
      if (this.bpSearch == null || this.bpSearch == "") return;
      if (this.bpSearch.length >= 3) {
        let data = {};
        data.name = this.bpSearch.toLowerCase();
        data.mostraOrdiniFatturati = this.mostraOrdini;
        let parameters = {};
        parameters.data = data;
        parameters.idempiereRestPath = "vecos/cercaCliente";
        this.$store
          .dispatch("callIdempiereRest", parameters)
          .then((response) => {
            this.bpartners = response.data.clienti;
          });
      }
    },
  },
};
</script>

<style>
</style>