<template>
  <v-container>
    <span v-show="tab == 0">
      <v-flex d-flex>
        <h2>Rendicontazione Straordinari</h2>
        <v-spacer></v-spacer>
        <v-btn @click="newStraordinari" text
          ><v-icon>mdi-plus</v-icon>Nuova registrazione</v-btn
        >
      </v-flex>
      <br />
      <v-row>
        <v-col>
          <v-autocomplete
            :items="bpartners"
            v-model="c_bpartner_id"
            label="Cliente"
            hide-no-data
            hide-selected
            item-text="name"
            item-value="c_bpartner_id"
            :search-input.sync="bpSearch"
            outlined
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col>
          <date-picker-text
            outlined
            dense
            label="Data da"
            hide-details
            v-model="dateFrom"
            clearable
          ></date-picker-text>
        </v-col>
        <v-col>
          <date-picker-text
            outlined
            dense
            label="Data a"
            hide-details
            v-model="dateTo"
            clearable
          ></date-picker-text>
        </v-col>
      </v-row>
      <br />
      <v-flex d-flex>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="getStraordinari">Cerca</v-btn>
      </v-flex>
      <br />
      <v-data-table
        :items="straordinari"
        item-key="fct_straordinari_id"
        :headers="headers"
        dense
        @click:row="editStraordinari"
        style="cursor: pointer"
      >
        <template v-slot:item.dateRegistered="{ item }">
          {{ dateToItalianFormat(item.dateRegistered) }}
        </template>
        <template v-slot:item.oreStraordinarie="{ item }">
          {{ getTime(item.oreStraordinarie) }}
        </template>
      </v-data-table>
      <v-flex d-flex>
        <v-spacer></v-spacer>
        <v-btn color="vecos" @click="openDialog">Esporta</v-btn>
      </v-flex>
    </span>
    <span v-show="tab == 1">
      <v-btn text @click="tab = 0"
        ><v-icon>mdi-chevron-left</v-icon>Indietro</v-btn
      >
      <Window
        name="Rendicontazione Straordinari"
        v-model="record_id"
        hide-copy
        @save="onSaveDelete"
        @delete="onSaveDelete"
      ></Window>
    </span>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>Export Rendicontazione Straordinari</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <date-picker-text
                outlined
                dense
                label="Data da"
                hide-details
                v-model="dateFromExport"
                clearable
              ></date-picker-text>
            </v-col>
            <v-col>
              <date-picker-text
                outlined
                dense
                label="Data a"
                hide-details
                v-model="dateToExport"
              ></date-picker-text>
            </v-col>
            <v-col v-if="isUserAdmin">
              <v-autocomplete
                v-model="ad_user_id"
                outlined
                dense
                :items="technicians"
                item-value="ad_user_id"
                item-text="name"
                label="Utente"
                hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="dialog = false">Chiudi</v-btn>
          <v-btn
            text
            color="primary"
            @click="downloadRendicontazione"
            :disabled="!dateFromExport || !dateToExport"
            >Export</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable */
import Window from "../components/Window.vue";
import DatePickerText from "../components/DatePickerText.vue";
import mixin from "../mixin/MixingCommonComp";

export default {
  mixins: [mixin],
  components: { Window, DatePickerText },
  data() {
    return {
      straordinari: [],
      headers: [
        { text: "Business Partner", value: "bpname" },
        { text: "Data Registrazione", value: "dateRegistered" },
        { text: "Ore straordinarie", value: "oreStraordinarie" },
        { text: "Descrizione", value: "description" },
      ],
      record_id: 0,
      tab: 0,
      dateFrom: "",
      dateTo: "",
      bpSearch: "",
      bpartners: [],
      c_bpartner_id: 0,
      dialog: false,
      technicians: [],
      ad_user_id: 0,
      dateFromExport: "",
      dateToExport: "",
      roles: []
    };
  },
  methods: {
    getStraordinari() {
      let parameters = {};
      parameters.data = {
        c_bpartner_id: this.c_bpartner_id,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
      parameters.idempiereRestPath = "vecos/getStraordinari";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        console.log(response);
        this.straordinari = response.data.straordinari;
      });
    },
    getTechnicians() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "vecos/getTechnicians";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.technicians = response.data.technicians;
      });
    },
    getUserRoles() {
      let ad_user_id = this.$session.get("ad_user_id");
      let parameters = {};
      parameters.data = { ad_user_id: ad_user_id };
      parameters.idempiereRestPath = "vecos/getUserRoles";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.roles = response.data.roles;
      });
    },
    newStraordinari() {
      this.record_id = 0;
      this.tab = 1;
    },
    editStraordinari(item) {
      this.record_id = item.fct_straordinari_id;
      this.tab = 1;
    },
    onSaveDelete() {
      this.getStraordinari();
      this.tab = 0;
    },
    getTime(value) {
      let date = new Date(value);

      return (
        String(date.getHours()).padStart(2, "0") +
        ":" +
        String(date.getMinutes()).padStart(2, "0")
      );
    },
    openDialog() {
      this.dateFromExport = this.dateFrom;
      this.dateToExport = this.dateTo;
      this.ad_user_id = 0;
      this.dialog = true;
    },
    downloadRendicontazione() {
      this.dialog = false;
      this.showLoadingDialog(true, "Download in corso");
      let parameters = {};
      parameters.data = {
        nomeStampa: "Rendicontazione Straordinari",
        parameters: JSON.stringify({
          DATE_FROM: this.dateFromExport,
          DATE_TO: this.dateToExport,
          AD_USER_ID: this.isUserAdmin ? this.ad_user_id : this.$session.get("ad_user_id"),
        }),
      };
      parameters.idempiereRestPath = "print/addPrintJob";

      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        console.log(response);
        if (response.data.hasOwnProperty("file")) {
          const linkSource =
            "data:application/pdf;base64," + response.data.file;
          const downloadLink = document.createElement("a");
          const fileName = "Rendicontazione" + ".pdf";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.showLoadingDialog(false);
        } else {
          var errorMsg = "Download non riuscito";
          if (response.data.hasOwnProperty("message")) {
            errorMsg = errorMsg + "\n" + response.data.message;
          }
          this.showErrorDialog(errorMsg);
        }
      });
    },
  },
  mounted() {
    this.getUserRoles();
    this.getTechnicians();

    let date = new Date();
    date.setMonth(date.getMonth() - 6);
    this.dateFrom = date.toISOString().substring(0, 10);

    date = new Date();
    date.setMonth(date.getMonth() + 6);
    this.dateTo = date.toISOString().substring(0, 10);

    this.getStraordinari();
  },
  computed:{
    isUserAdmin(){
      let adminRoles = [1000031, 1000033, 1000033, 1000017];

      for(let role of this.roles){
        if(adminRoles.includes(role.ad_role_id)){
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    bpSearch: function () {
      if (this.bpSearch == null || this.bpSearch == "") return;
      if (this.bpSearch.length >= 3) {
        let data = {};
        data.name = this.bpSearch.toLowerCase();
        data.mostraOrdiniFatturati = this.mostraOrdini;
        let parameters = {};
        parameters.data = data;
        parameters.idempiereRestPath = "vecos/cercaCliente";
        this.$store
          .dispatch("callIdempiereRest", parameters)
          .then((response) => {
            this.bpartners = response.data.clienti;
          });
      }
    },
  },
};
</script>

<style>
</style>