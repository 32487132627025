
<template>
  <v-container fluid>
    <h1 class="value-center">Progetti da fatturare</h1>

    <v-tabs v-model="tab">
      <v-tab v-model="tab">Lista progetti da fatturare</v-tab>
      <v-tab disabled v-model="tab">Attribuzione spese</v-tab>
      <v-tab disabled v-model="tab">Riepilogo</v-tab>

      <!-- LISTA PROGETTI DA FATTURARE-->
      <v-tab-item>
        <v-row>
          <v-col>
            <v-autocomplete
              clearable
              density="compact"
              label="Cliente"
              hide-no-data
              hide-selected
              :items="customers"
              item-text="name"
              item-value="c_bpartner_id"
              :search-input.sync="ricercaCliente"
              v-model="c_BPartner_ID"
            ></v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete
              clearable
              v-model="documentno"
              label="Ordine di vendita"
              :items="odvs"
              item-text="odv"
            ></v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete
              clearable
              item-text="description"
              :items="descriptions"
              v-model="description"
              label="Descrizione"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-responsive align="center">
          <v-btn class="my-4" @click="searchFilteredInvoices()">Cerca</v-btn>
        </v-responsive>

        <v-data-table
          v-model="selected"
          :items="invoices"
          :items-per-page="20"
          show-select
          :headers="headers"
          item-key="rv_progettidafatturare_id"
        >
      </v-data-table>

        <br />
        <v-responsive align="right">
          <v-btn class="mr-4 mb-4" @click="checkOrderId()">Crea fatture</v-btn>
        </v-responsive>
      </v-tab-item>

      <!-- ATTRIBUZIONE SPESE VIAGGIO -->
      <v-tab-item>
        <h1 class="text-center">Attribuzione spese</h1>

        <v-card class="mx-auto mt-8" max-width="700">
          <v-card-title class="text-center">
            Attribuzione spese viaggio</v-card-title
          >

          <h3 class="mx-8">
            Spese viaggio totali:
            <b class="mx-4">
              {{ totalAmount }}
            </b>
          </h3>
          <br />

          <h3 class="mx-8">
            Spese viaggio già fatturate:
            <b class="mx-4">
              {{ amountAlreadyInvoiced }}
            </b>
          </h3>
          <br />
          <v-row>
            <v-col>
              <h3 class="mx-8 mt-4">Spese viaggio da attribuire:</h3>
            </v-col>
            <v-col>
              <v-responsive max-width="200">
                <v-text-field :disabled="totalAmount == 0"
                  type="number"
                  min="0"
                  class="mb-16"
                  v-model="quantity"
                  label="Inserisci valore"
                ></v-text-field>
              </v-responsive>
            </v-col>
          </v-row>

          <v-row>
            <v-responsive align="center">
              <v-btn class="mb-6" @click="goBack()"
                >Torna alla selezione progetti</v-btn
              >
            </v-responsive>

            <v-responsive align="center">
              <v-btn class="mb-6" @click="goForward()"
                >Prosegui al riepilogo</v-btn
              >
            </v-responsive>
          </v-row>
        </v-card>
      </v-tab-item>

      <!-- RIEPILOGO-->
      <v-tab-item>
        <h1 class="text-center">Riepilogo operazione</h1>
        <v-card class="mx-auto mt-8" max-width="1400">
          <v-card-title class="text-center">Riepilogo</v-card-title>

          <v-data-table
            class="mb-8"
            :headers="headers"
            :items="selected"
            :hide-default-footer="true"
            :items-per-page="-1"
          >
          </v-data-table>

          <h3 class="mb-8 mx-4">Spese viaggio da attribuire: {{ quantity }}</h3>

          <br>
          <v-responsive align="center" width="600">
            <v-row>
              <v-col>
                <h3 class="mt-4"> Data di fatturazione: </h3>
              </v-col>
              <v-col>
                <DataPicker v-model="invoiceDate" class="mb-4"></DataPicker>
              </v-col>
            </v-row>
          </v-responsive>
          
          <v-row>
            <v-responsive align="center">
                <v-btn class="mb-6 mr-2" @click="goToList()"
                >Torna alla lista progetti</v-btn>

                <v-btn :disabled="this.selected.length != 0 && this.selected[0].c_projectline_id" class="mb-6" @click="goBack()"
                >Torna alle spese viaggio</v-btn>
            </v-responsive>

            <v-responsive align="center">
              <v-btn class="mb-6" @click="createInvoice()">Crea fattura</v-btn>
            </v-responsive>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>


<script>
/*eslint-disable */
import mixin from "@/mixin/MixingCommonComp";
import DialogError from "../components/DialogError.vue";
import DialogOperationRunning from "../components/DialogOperationRunning.vue";
import DataPicker from "../components/DataPicker.vue";
import { Conf } from "../conf/defaults.js"
export default {
  components: { DialogError, DialogOperationRunning, DataPicker, Conf },
  mixins: [mixin],
  data() {
    return {
      invoices: [],
      customers: [],
      selected: [],
      odvs: [],
      descriptions: [],
      documentno: "",
      c_BPartner_ID: 0,
      description: "",
      invoiced: false,
      ricercaCliente: "",
      mostraOrdini: false,

      tab: null,
      c_order_id: "",
      quantity: "",
      totalAmount: "",
      amountAlreadyInvoiced: "",
      invoiceDate: new Date().toISOString().substr(0, 10),

      //rv_progettidafatturare

      headers: [
        { value: "bpname", text: "Nome BP", width: "15%" },
        { value: "documentno", text: "Ordine di vendita", width: "5%" },
        { value: "productname", text: "Prodotto", width: "10%" },
        { value: "matricolainail", text: "Matricola Inail" },
        { value: "dateeff", text: "Date Att. Eff." },
        {
          value: "righeordinestat",
          text: "Righe ordine terminate/Totali",
          width: "1%",
        },
        { value: "description", text: "Descrizione", width: "30%" },
        {
          value: "productionplant",
          text: "Stabilimento Produttivo",
          width: "15%",
        },
      ],
    };
  },
  methods: {
    ricercaCliente(ricerca) {
      if (ricerca == null || ricerca == "" || ricerca.length < 3) return;
      let parameters = {};
      parameters.data = { text: ricerca };
      parameters.idempiereRestPath = "effecitech/cercaCliente";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.customers = response.data.listaClienti;
        }
      });
    },
    getInvoices() {
      this.showLoadingDialog(true, "Ricerca progetti da fatturare in corso...");
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "seritec/getInvoices";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.invoices = response.data.invoices;
        console.log(response);
        this.showLoadingDialog(false);
      });
    },
    getOdv() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "seritec/getOdv";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.odvs = response.data.odvs;
      });
    },
    searchInvoices() {
      let parameters = {};
      parameters.data = {
        c_BPartner_ID: this.c_BPartner_ID,
        documentNo: this.documentNo,
        description: this.description,
      };
      parameters.idempiereRestPath = "seritec/searchInvoices";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.invoices = response.data.invoices;
      });
    },
    getDescriptions() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "seritec/getDescriptions";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.descriptions = response.data.descriptions;
      });
    },
    searchFilteredInvoices() {
      this.invoices = [];
      this.showLoadingDialog(true, "Ricerca progetti da fatturare in corso...")
      if (
        (this.c_BPartner_ID == 0 || this.c_BPartner_ID == null) &&
        (this.description == "" || this.description == null) &&
        (this.documentno == "" || this.documentno == null)
      ) {
        this.getInvoices();
        return;
      }
      let parameters = {};
      parameters.data = {
        c_bpartner_id: this.c_BPartner_ID,
        documentno: this.documentno,
        description: this.description,
      };
      parameters.idempiereRestPath = "seritec/getFilteredInvoices";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.invoices = response.data.invoices;
        this.showLoadingDialog(false);
        console.log(response.data);
      });
    },
    checkOrderId() {
      let signal = false;
      if (this.selected.length > 0) {
        this.c_order_id = this.selected[0].c_order_id;
        for (const i in this.selected) {
          if (this.selected[i].c_order_id != this.c_order_id) {
            this.showErrorDialog(
              "Hai selezionato più ordini con ID differenti"
            );
            this.tab = 0;
            signal = true;
          }
        }
        if (signal == false) {
          this.goForward();
          this.showTotalAmount();
          this.showAmountAlreadyInvoiced();
        }
      }
      if (this.selected.length <= 0) {
        this.showErrorDialog("Seleziona almeno un progetto da fatturare");
      }
    },
    showTotalAmount() {
      let parameters = {};
      parameters.data = { c_order_id: this.c_order_id };
      parameters.idempiereRestPath = "seritec/showTotalAmount";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.totalAmount = response.data;
      });
    },
    showAmountAlreadyInvoiced() {
      let parameters = {};
      parameters.data = { c_order_id: this.c_order_id };
      parameters.idempiereRestPath = "seritec/showAmountAlreadyInvoiced";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.amountAlreadyInvoiced = response.data;
      });
    },

    goForward() {
      if (this.selected.length <= 0) {
        this.showErrorDialog("Seleziona almeno un progetto da fatturare");
        this.tab = 0;
      }
      if(this.selected.length != 0 && this.selected[0].c_projectline_id){
        this.tab = 2;
        return;
      }
      if (this.tab == 1 && this.quantity == "") {
        this.quantity = 0;
      }
      this.tab = this.tab + 1;
    },
    goBack() {
      this.tab = this.tab - 1;
    },
    goToList() {
      this.tab = 0;
    },
    
     createInvoice() {
      this.showLoadingDialog(true, "Creazione fattura in corso");

      let lines = [];
      for (const i in this.selected) {
        lines.push({
          c_orderline_id: this.selected[i].c_orderline_id,
          rv_progettidafatturare_id: this.selected[i].rv_progettidafatturare_id,
        });
      }
      this.tab = this.tab + 1;
      if (this.quantity == "") {
        this.quantity = 0;
      }

      let parameters = {};
      parameters.data = { qtyTravelCharge: this.quantity, orderLines: lines, dateInvoiced: this.invoiceDate };
      parameters.idempiereRestPath = "seritec/createInvoice";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        console.log(response.data);
        this.selected = [];
        this.orderlines_ids = [];
        this.quantity = "";
        this.getInvoices();
        this.showLoadingDialog(false);
        console.log(response);
        this.showInfoDialog(
          "<b>Creata la fattura " +
          '</b><a target="_blank" href="'+Conf.idempiereUrl+'webui/index.zul?Action=Zoom&AD_Table_ID=318&Record_ID='+
          response.data.c_invoice_id +
          "\"><b>"+ response.data.documentNo +"<b></a>"
        );
        this.ricercaCliente="";
      });
    },
  },
  watch: {
    ricercaCliente: function () {
      if (this.ricercaCliente == null || this.ricercaCliente == "") return;
      if (this.ricercaCliente.length >= 3) {
        let data = {};
        data.name = this.ricercaCliente.toLowerCase();
        data.mostraOrdiniFatturati = this.mostraOrdini;
        let parameters = {};
        parameters.data = data;
        parameters.idempiereRestPath = "vecos/cercaCliente";
        this.$store
          .dispatch("callIdempiereRest", parameters)
          .then((response) => {
            this.customers = response.data.clienti;
          });
      }
    },
  },
  async mounted() {
    /*"no-console": "off",*/
    await this.getDescriptions();
    await this.getInvoices();
    await this.getOdv();
  },
};
</script>