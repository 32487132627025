<template>
  <span v-if="tab">
    <v-flex d-flex>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="openTabDialog()"
        :disabled="!parent_id || parent_id.value <= 0"
        ><v-icon>mdi-plus</v-icon>Nuovo</v-btn
      >
    </v-flex>
    <v-simple-table dense>
      <thead>
        <tr>
          <th v-for="field in getGridFields(tab)" :key="field.AD_Field_ID">
            {{ field.Trl[language].Name }}
          </th>
          <th>Elimina</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(record, index) in data" :key="index">
          <td
            v-for="field in getGridFields(tab)"
            :key="field.AD_Field_ID"
            class="clickable"
            @click="openTabDialog(record)"
          >
            <TextTable
              v-if="field.Column.Reference.Name == 'Table Direct'"
              v-model="record[field.Column.ColumnName]"
              :column="field.Column"
            ></TextTable>
            <TextTable
              v-else-if="field.Column.Reference.Name == 'Table'"
              v-model="record[field.Column.ColumnName]"
              :column="field.Column"
            ></TextTable>
            <TextTable
              v-else-if="field.Column.Reference.Name == 'Search'"
              v-model="record[field.Column.ColumnName]"
              :column="field.Column"
            ></TextTable>
            <TextTable
              v-else-if="field.Column.Reference.Name == 'Locator (WH)'"
              v-model="record[field.Column.ColumnName]"
              :column="field.Column"
            ></TextTable>
            <TextTable
              v-else-if="field.Column.Reference.Name == 'ID'"
              v-model="record[field.Column.ColumnName]"
              :column="field.Column"
            ></TextTable>
            <TextTable
              v-else-if="field.Column.Reference.Name == 'Product Attribute'"
              v-model="record[field.Column.ColumnName]"
              :column="field.Column"
            ></TextTable>
            <TextList
              v-else-if="field.Column.Reference.Name == 'List'"
              v-model="record[field.Column.ColumnName]"
              :column="field.Column"
            ></TextList>
            <v-checkbox
              v-else-if="field.Column.Reference.Name == 'Yes-No'"
              v-model="record[field.Column.ColumnName]"
              disabled
              small
              dense
              true-value="Y"
              false-value="N"
              hide-details
            ></v-checkbox>
            <span v-else> {{ record[field.Column.ColumnName] }}</span>
          </td>
          <td>
            <v-btn icon small @click="deleteRow(record)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-model="tabDialog">
      <v-card>
        <v-card-text>
          <Tab
            v-model="id"
            :ad_tab_id="tab.AD_Tab_ID"
            :parent_id="parent_id"
            :language="language"
            hide-save
            hide-delete
            hide-copy
            ref="tab"
          ></Tab>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteRecord" :disabled="id <= 0"
            ><v-icon>mdi-delete</v-icon>Elimina</v-btn
          >
          <v-btn text @click="copyRecord" :disabled="id <= 0"
            ><v-icon>mdi-content-copy</v-icon>Copia</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="tabDialog = false">Annulla</v-btn>
          <v-btn text color="success" @click="saveRecord">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
  </span>
</template>

<script>
/*eslint-disable */
import TextTable from "./TextTable.vue";
import ConfirmDialog from "./ConfirmDialog.vue";
import mixin from "../mixin/MixingCommonComp";
import TextList from "./TextList.vue";

export default {
  name: "TabGridTable",
  mixins: [mixin],
  components: {
    TextTable,
    Tab: () => import("./Tab.vue"),
    ConfirmDialog,
    TextList,
  },
  props: {
    ad_tab_id: { type: Number },
    parent_tab_id: { type: Number },
    record_id: { type: Number, default: 0 },
    language: { type: String, default: "it_IT" },
  },
  data() {
    return {
      tab: null,
      parentTab: null,
      data: [],
      tabDialog: false,
      id: 0,
    };
  },
  methods: {
    getTab() {
      let parameters = {};
      parameters.data = { ad_tab_id: this.ad_tab_id };
      parameters.idempiereRestPath = "window/getTab";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.tab = response.data.tab;
        this.getTabData();
      });
    },
    getParentTab() {
      let parameters = {};
      parameters.data = { ad_tab_id: this.parent_tab_id };
      parameters.idempiereRestPath = "window/getTab";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.parentTab = response.data.tab;
        this.getTabData();
      });
    },
    getTabData() {
      let parameters = {};
      parameters.data = {
        ad_tab_id: this.tab.AD_Tab_ID,
        parent_tab_id: this.parent_tab_id,
        record_id: this.record_id,
      };
      parameters.idempiereRestPath = "window/getTabData";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.data = response.data.data;
      });
    },
    getGridFields(tab) {
      let fields = [];
      for (let field of tab.Fields) {
        if (field.IsDisplayedGrid && field.Column.Reference.Name != "Button") {
          fields.push(field);
        }
      }
      fields.sort((a, b) => {
        return a.SeqNoGrid > b.SeqNoGrid ? 1 : -1;
      });
      return fields;
    },
    getID(record) {
      for (let field of this.tab.Fields) {
        if (field.Column.IsKey) {
          return record[field.Column.ColumnName];
        }
      }
      return 0;
    },
    getIDName(tab) {
      for (let field of tab.Fields) {
        if (field.Column.IsKey) {
          return field.Column.ColumnName;
        }
      }
      return null;
    },
    openTabDialog(record = null) {
      if (!record) {
        this.id = 0;
      } else {
        this.id = this.getID(record);
      }
      this.tabDialog = true;
    },
    async saveRecord() {
      let success = await this.$refs.tab.save();
      if (success.data.result == "OK") {
        this.getTabData();
        this.tabDialog = false;
      }
    },
    async deleteRow(record) {
      if (
        !(await this.$refs.confirm.open(
          "Elimina",
          "Vuoi eliminare questo record"
        ))
      ) {
        return;
      }

      let parameters = {};
      parameters.data = {
        ad_tab_id: this.ad_tab_id,
        record_id: this.getID(record),
      };
      parameters.idempiereRestPath = "window/deleteRecord";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.getTabData();
        } else {
          this.showErrorDialog(response.data.message);
        }
      });
    },
    async deleteRecord() {
      let response = await this.$refs.tab.deleteRecord();
      if (response.data.result == "OK") {
        this.getTabData();
        this.tabDialog = false;
      }
    },
    copyRecord() {
      this.$refs.tab.copyRecord();
    },
  },
  mounted() {
    this.getTab();
    this.getParentTab();
  },
  computed: {
    parent_id: function () {
      if (!this.parentTab) return null;
      let name = this.getIDName(this.parentTab);
      return { name: name, value: this.record_id };
    },
  },
  watch: {
    ad_tab_id: function () {
      this.getTab();
      this.getParentTab();
    },
    parent_tab_id: function () {
      if (this.tab && this.parent_tab_id && this.record_id) {
        this.getTab();
        this.getParentTab();
      }
    },
    record_id: function () {
      if (this.tab && this.parent_tab_id && this.record_id) {
        this.getTab();
        this.getParentTab();
      }
    },
  },
};
</script>

<style>
.clickable {
  cursor: pointer;
}
</style>