<template>
  <v-container>
    <v-stepper v-model="numeroScheda" non-linear>
      <v-stepper-header>
        <v-stepper-step editable :complete="numeroScheda > 1" step="1"
          >Dati generali</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step editable :complete="numeroScheda > 2" step="2"
          >Rilevazioni</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step editable :complete="numeroScheda > 3" step="3"
          >Rilevazioni</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step editable :complete="numeroScheda > 4" step="4"
          >Esito</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step editable :complete="numeroScheda > 5" step="5"
          >Firme</v-stepper-step
        >
      </v-stepper-header>
      <v-form v-model="form" ref="validForm">
        <v-stepper-items>
          <!--Prima scheda - Dati generali-->
          <v-stepper-content step="1">
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs6 sm6 md6 lg6 xl6>
                <DataPicker
                  label="Data verbale"
                  v-model="verbale.dataVerifica"
                  id="dataVerbale"
                  dense
                ></DataPicker>
              </v-col>
              <v-col xs6 sm6 md6 lg6 xl6>
                <v-select
                  label="Verificatore"
                  outlined
                  :items="listaVerificatori"
                  item-text="name"
                  item-value="ad_user_id"
                  v-model="verbale.ad_userverificatore_id"
                  no-data-text
                  :rules="[rules.select]"
                  id="verificatore"
                  dense
                ></v-select>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-select
                  :items="tipologiaMacchinario"
                  outlined
                  label="Tipo Attrezzatura"
                  no-data-text="Nessun dato"
                  v-model="verbale.tipologiaMacchinario"
                  item-text="name"
                  item-value="id"
                  id="tipologiaMacchinario"
                  :rules="[rules.select]"
                  dense
                ></v-select>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-text-field
                  outlined
                  label="Matricola"
                  v-model="verbale.matricola"
                  id="matricola"
                  dense
                ></v-text-field>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-text-field
                  label="Marcatura CE"
                  v-model="verbale.certCE"
                  :rules="[rules.required]"
                  outlined
                  id="certCE"
                  dense
                ></v-text-field>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs4 sm4 md4 lg4 xl4>
                <v-text-field
                  label="Costruttore"
                  outlined
                  v-model="verbale.marca"
                  :rules="[rules.required]"
                  id="costruttore"
                  dense
                ></v-text-field>
              </v-col>
              <v-col xs4 sm4 md4 lg4 xl4>
                <v-text-field
                  label="Numero di Fabbrica"
                  outlined
                  v-model="verbale.nFab"
                  :rules="[rules.required]"
                  id="nfab"
                  dense
                ></v-text-field>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs6 sm6 md6 lg6 xl6>
                <v-text-field
                  outlined
                  label="Gruppo Fluido"
                  v-model="verbale.gruppoFluido"
                  :rules="[rules.required]"
                  id="gruppoFluido"
                  dense
                ></v-text-field>
              </v-col>
              <v-col xs6 sm6 md6 lg6 xl6>
                <v-text-field
                  outlined
                  label="Categoria"
                  v-model="verbale.categoria"
                  :rules="[rules.required]"
                  id="categoria"
                  dense
                ></v-text-field>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-text-field
                  outlined
                  label="Tabella PED"
                  v-model="verbale.tabellaPED"
                  :rules="[rules.required]"
                  id="tabellaPED"
                  dense
                ></v-text-field>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-text-field
                  label="Pressione(bar)"
                  v-model="verbale.pressione"
                  :rules="[rules.required]"
                  outlined
                  id="pressione"
                  dense
                ></v-text-field>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs6 sm6 md6 lg6 xl6>
                <v-text-field
                  outlined
                  label="Temperatura(°C)"
                  v-model="verbale.temperatura"
                  :rules="[rules.required]"
                  id="temperatura"
                  dense
                ></v-text-field>
              </v-col>
              <v-col xs6 sm6 md6 lg6 xl6>
                <v-text-field
                  outlined
                  label="Capacità(litri)"
                  v-model="verbale.capacita"
                  :rules="[rules.required]"
                  id="capacita"
                  dense
                ></v-text-field>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-text-field
                  outlined
                  label="Superficie(m²)"
                  v-model="verbale.superficie"
                  :rules="[rules.required]"
                  id="superficie"
                  dense
                ></v-text-field>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-text-field
                  label="Producibilità"
                  v-model="verbale.producibilita"
                  :rules="[rules.required]"
                  outlined
                  id="producibilita"
                  dense
                ></v-text-field>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col>
                <v-text-field
                  label="Installato/utilizzato nel cantiere/stabilimento della ditta"
                  outlined
                  v-model="verbale.ditta"
                  :rules="[rules.required]"
                  id="ditta"
                  dense
                ></v-text-field>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs4 sm4 md4 lg4 xl4>
                <v-text-field
                  label="Comune"
                  outlined
                  v-model="verbale.comuneDitta"
                  :rules="[rules.required]"
                  id="comuneDitta"
                  dense
                ></v-text-field>
              </v-col>
              <v-col xs4 sm4 md4 lg4 xl4>
                <v-text-field
                  label="Via"
                  outlined
                  v-model="verbale.viaDitta"
                  :rules="[rules.required]"
                  id="viaDitta"
                  dense
                ></v-text-field>
              </v-col>
              <!--<v-col xs1 sm1 md1 lg1 xl1>
                <v-text-field
                  label="Numero civico"
                  outlined
                  v-model="verbale.numeroCivicoDitta"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>-->
            </v-flex>
          </v-stepper-content>
          <!--Fine prima scheda-->

          <!-- Seconda scheda - Rilevazioni -->
          <v-stepper-content step="2">
            <h4>PROVE E VERIFICHE TECNICHE ESEGUITE</h4>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-checkbox
                  label="Straordinaria"
                  v-model="verbale.isStraordinaria"
                  id="isStraordinaria"
                ></v-checkbox>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-checkbox
                  label="Periodica"
                  v-model="verbale.isPeriodica"
                  id="isPeriodica"
                ></v-checkbox>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-checkbox
                  label="Riparazione"
                  v-model="verbale.isRiparazione"
                  id="isRiparazione"
                ></v-checkbox>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-checkbox
                  label="Sopralluogo"
                  v-model="verbale.isSopralluogo"
                  id="isSopralluogo"
                ></v-checkbox>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-checkbox
                  label="Esercizio"
                  v-model="verbale.isEsercizio"
                  id="isEsercizio"
                ></v-checkbox>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-checkbox
                  label="Integrità"
                  v-model="verbale.isIntegrita"
                  id="isIntegrita"
                ></v-checkbox>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-checkbox
                  label="Interna"
                  v-model="verbale.isInterna"
                  id="isInterna"
                ></v-checkbox>
              </v-col>
            </v-flex>
            <v-textarea
              label="Testo"
              outlined
              :rules="[rules.required]"
              v-model="verbale.testo"
              id="testo"
            ></v-textarea>
            <v-textarea
              label="Accessori di Sicurezza"
              outlined
              :rules="[rules.required]"
              v-model="verbale.accessoriSicurezza"
              id="accessoriSicurezza"
            ></v-textarea>
            <v-textarea
              label="Dispositivi di controllo"
              outlined
              :rules="[rules.required]"
              v-model="verbale.dispositiviControllo"
              id="dispositiviControllo"
            ></v-textarea>
          </v-stepper-content>
          <!-- Fine seconda scheda-->

          <!--Terza scheda - Rilevazioni-->
          <v-stepper-content step="3">
            <v-textarea
              label="Dispositivi di regolazione"
              outlined
              :rules="[rules.required]"
              v-model="verbale.dispositiviRegolazione"
              id="dispositiviRegolazione"
            ></v-textarea>
            <v-textarea
              label="Altri Accessori Rilevanti"
              outlined
              :rules="[rules.required]"
              v-model="verbale.altriAccessori"
              id="altriAccessori"
            ></v-textarea>
            <v-textarea
              label="Valvole di intercettazione"
              outlined
              :rules="[rules.required]"
              v-model="verbale.valvoleIntercettazione"
              id="valvoleIntercettazione"
            ></v-textarea>
            <v-textarea
              label="Verifica di visita interna"
              outlined
              :rules="[rules.required]"
              v-model="verbale.verificaInterna"
              id="verificaInterna"
            ></v-textarea>
            <v-textarea
              label="Verifica di integrità"
              outlined
              :rules="[rules.required]"
              v-model="verbale.verificaIntegrita"
              id="verificaIntegrita"
            ></v-textarea>
          </v-stepper-content>
          <!--Fine terza scheda-->

          <!--Quarta scheda - Esito-->
          <v-stepper-content step="4">
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs12 sm12 md12 lg12 xl12>
                <v-textarea
                  label="Note"
                  outlined
                  v-model="verbale.note"
                  :rules="[rules.required]"
                  id="note"
                  dense
                ></v-textarea>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs6 sm6 md6 lg6 xl6>
                <v-select
                  label="Esito"
                  id="esito"
                  outlined
                  v-model="verbale.esito"
                  :items="listaEsiti"
                  item-text="text"
                  item-value="value"
                  :rules="[rules.select]"
                ></v-select>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs6 sm6 md6 lg6 xl6 v-if="verbale.isEsercizio">
                <DataPicker
                  label="Data prossima funzionamento"
                  v-model="verbale.dataProssimaFun"
                  :rules="[rules.required]"
                ></DataPicker>
              </v-col>
              <v-col xs6 sm6 md6 lg6 xl6 v-if="verbale.isIntegrita">
                <DataPicker
                  label="Data prossima integrità"
                  v-model="verbale.dataProssimaIntg"
                  :rules="[rules.required]"
                ></DataPicker>
              </v-col>
              <v-col xs6 sm6 md6 lg6 xl6 v-if="verbale.isInterna">
                <DataPicker
                  label="Data prossima interna"
                  v-model="verbale.dataProssimaInt"
                  :rules="[rules.required]"
                ></DataPicker>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs12 sm12 md12 lg12 xl12>
                <v-text-field
                  label="Luogo"
                  outlined
                  v-model="verbale.luogoVerifica"
                  :rules="[rules.required]"
                  id="luogoVerifica"
                ></v-text-field>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-checkbox
                v-model="verbale.updateScad"
                label="Aggiorna dati sullo scadenziario"
                id="updateScad"
              ></v-checkbox>
            </v-flex>
          </v-stepper-content>
          <!--Fine Quarta scheda-->

          <!--Quinta scheda - Firme-->
          <v-stepper-content step="5">
            <v-flex
              xs12
              sm12
              md12
              lg12
              xl12
              class="space-up"
              v-if="numeroScheda == 5"
            >
              <h4>Firma cliente</h4>
              <VueSignaturePad
                style="border: 1px solid #6c757d"
                id="firmaDatore"
                width="351"
                height="90"
                ref="firmaDatore"
              />
            </v-flex>
            <br />
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-btn color="error" @click="$refs.firmaDatore.clearSignature()"
                >Cancella firma</v-btn
              >
            </v-flex>
            <br />
            <br />
          </v-stepper-content>
          <!--Fine Quinta scheda-->
        </v-stepper-items>
      </v-form>
    </v-stepper>
    <v-card mr-5>
      <v-footer fixed>
        <v-container>
          <v-flex d-flex>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="schedaPrecedente"
              :disabled="numeroScheda <= 1"
              id="schedaPrecedente"
              >Indietro</v-btn
            >
            <v-btn
              v-if="numeroScheda < schedeTotali"
              color="primary"
              @click="schedaSuccessiva"
              id="schedaSuccessiva"
              >Avanti</v-btn
            >
            <v-btn
              v-else
              color="primary"
              @click="salvaVerbale"
              :disabled="saveDisabled || !form"
              id="salvaVerbale"
              >Salva</v-btn
            >
          </v-flex>
        </v-container>
      </v-footer>
    </v-card>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
  </v-container>
</template>

<script>
/*eslint-disable*/
import mixin from "../mixin/MixingCommonComp";


import DataPicker from "../components/DataPicker";
import ConfirmDialog from "../components/ConfirmDialog";

export default {
  mixins:[mixin],
  components: {
    DataPicker,
    ConfirmDialog,
  },
  data() {
    return {
      numeroScheda: 1,
      schedeTotali: 5,
      form: null,
      saveDisabled: false,
      verbale: {
        dataVerifica: new Date().toISOString().substr(0, 10),
        tipologiaMacchinario: 0,
        matricola: "",
        certCE: "SI",
        marca: "",
        ditta: "",
        nFab: "",
        comuneDitta: "",
        viaDitta: "",
        note: "La presente verifica viene eseguita, su richiesta dell'azienda, per la sicurezza in generale delle macchine e impianti ai\nsensi dell'articolo 71, comma 8, lettera b) e c) del D.lgs. 81/08.",
        testo: "",
        luogoVerifica: "",
        esito: "",
        altro: "",
        fct_vertecnicogvr_id: 0,
        ad_userverificatore_id: 0,
        gruppoFluido: "",
        categoria: "",
        tabellaPED: "",
        pressione: "",
        temperatura: "",
        capacita: "",
        superficie: "",
        producibilita: "",
        isStraordinaria: false,
        isPeriodica: false,
        isRiparazione: false,
        isSopralluogo: false,
        isEsercizio: false,
        isIntegrita: false,
        isInterna: false,
        updateScad: false,
      },
      listaVerificatori: [],
      tipologiaMacchinario: [],
      listaEsiti: [
        {
          text: "Positivo",
          value:
            "Nelle attuali condizioni d’impianto e di esercizio, l’attrezzatura/impianto <u>RISULTA</u> in ordine alle condizioni di sicurezza.",
        },
        {
          text: "Negativo",
          value:
            "Nelle attuali condizioni d’impianto e di esercizio, l’attrezzatura/impianto <u>NON RISULTA</u> in ordine alle condizioni di sicurezza.",
        },
      ],
      rules: {
        required: (value) => (value ? true : "Campo richiesto!"),
        select: (value) => value != 0,
      },
    };
  },
  methods: {
    schedaPrecedente() {
      if (this.numeroScheda > 1) {
        this.numeroScheda = parseInt(this.numeroScheda) - 1;
      }
    },
    schedaSuccessiva() {
      if (this.numeroScheda < this.schedeTotali) {
        this.numeroScheda = parseInt(this.numeroScheda) + 1;
      }
    },
    tornaAllaHome() {
      this.$router.push({ path: "/" });
    },
    toISOStringUTC(datestr) {
      var tzoffset = new Date().getTimezoneOffset() * 60000;
      return new Date(new Date(datestr) - tzoffset).toISOString().slice(0, -1);
    },
    async salvaVerbale() {
      if (!this.$refs.validForm.validate()) return;
      this.saveDisabled = true;
      this.$store.commit("setProgressMessage", "Salvaggio verbale");
      this.$store.commit("setProgressLoading", true);
      let data = Object.assign({}, this.verbale);
      if (this.$refs.firmaDatore.isEmpty()) {
        data.firmaTitolareBase64 = null;
      } else {
        data.firmaTitolareBase64 = this.$refs.firmaDatore
          .saveSignature()
          .data.split(",")[1];
      }
      data.dataVerifica = this.toISOStringUTC(data.dataVerifica);

      let parameters = {};
      parameters.data = data;
      parameters.idempiereRestPath =
        "seritec/inserisciAggiornaVerbaleTecnicoGVR";
      console.log(data);
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        console.log(response);
        if (response.data.fct_vertecnicogvr_id != 0) {
          if (this.$store.state.datiVerbale.isBadPayer) {
            this.showInfoDialog(
              "Salvataggio eseguito! \n Il cliente è un cattivo pagatore"
            );
          }

          let item = response.data;
          let parameters = {};
          parameters.idempiereRestPath = "print/addPrintJob";
          let data = {};
          data.parameters = "{id:" + item.fct_vertecnicogvr_id + "}";
          data.nomeStampa = "Verbale Tecnico GVR";
          parameters.data = data;
          this.$store
            .dispatch("callIdempiereRest", parameters)
            .then((response) => {
              console.log(response);
              if (response.data.hasOwnProperty("file")) {
                const linkSource =
                  "data:application/pdf;base64," + response.data.file;
                const downloadLink = document.createElement("a");
                const fileName = item.nomeVerbale + ".pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                var that = this;
                setTimeout(function () {
                  that.$store.commit("setProgressMessage", null);
                  that.$store.commit("setProgressLoading", true);
                  that.saveDisabled = false;
                  that.$router.push({ path: "/" });
                }, 5000);
              } else {
                this.$store.commit("setProgressMessage", null);
                var errorMsg = "Stampa non riuscita";
                if (response.data.hasOwnProperty("message")) {
                  errorMsg = errorMsg + "\n" + response.data.message;
                }
                this.$store.commit("setErrorMessage", errorMsg);
              }
            });
        }
      });
    },
  },
  mounted: async function () {
    this.$store.commit("setProgressMessage", "Caricamento dati macchina...");
    this.$vuetify.lang.current = "it";
    let data = {};
    let parameters = {};
    parameters.data = data;
    parameters.idempiereRestPath = "seritec/getListaVerificatori";
    await this.$store
      .dispatch("callIdempiereRest", parameters)
      .then((response) => {
        this.listaVerificatori = response.data.listaVerificatori;
        this.verbale.ad_userverificatore_id = this.$session.get("ad_user_id");
      });
    parameters.data.type = "tipoMacchinarioGVR";
    parameters.idempiereRestPath = "vecos/getVerbalOptions";
    await this.$store
      .dispatch("callIdempiereRest", parameters)
      .then((response) => {
        this.tipologiaMacchinario = response.data.opzioni;
      });
    if (
      this.$store.state.datiVerbale.type == "C" ||
      this.$store.state.datiVerbale.type == "M"
    ) {
      data = {};
      data.fct_vertecnicogvr_id = this.$store.state.datiVerbale.fct_vergvr_id;
      parameters = {};
      parameters.data = data;
      parameters.idempiereRestPath = "seritec/getVerbaleTecnicoGVR";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          console.log(response);
          this.verbale = Object.assign({}, response.data.verbale);
          this.firmaDatore = this.verbale.firmaDatore;
          delete this.verbale.firmaDatore;
          this.verbale.updateScad = true;
          if (this.$store.state.datiVerbale.type == "C") {
            this.verbale.fct_vertecnicogvr_id = 0;
            this.firmaDatore = "";
            this.verbale.dataVerifica = new Date().toISOString().substr(0, 10);
            this.verbale.dataProssimaFun = "";
            this.verbale.dataProssimaInt = "";
            this.verbale.dataProssimaIntg = "";
            this.verbale.c_bpartner_id =
              this.$store.state.datiVerbale.c_bpartner_id;
            this.verbale.fct_scadverifiche_id =
              this.$store.state.datiVerbale.fct_scadverifiche_id;
            this.verbale.c_orderline_id =
              this.$store.state.datiVerbale.c_orderline_id;
          } else {
            this.verbale.updateScad = false;
            this.verbale.fct_vertecnicogvr_id = parseInt(
              this.$store.state.datiVerbale.fct_vergvr_id
            );
          }
        });
    } else {
      data = {};
      data.fct_scadverifiche_id =
        this.$store.state.datiVerbale.fct_scadverifiche_id;
      parameters.data = data;
      parameters.idempiereRestPath = "vecos/getDatiMacchina";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          let macchinario = response.data.macchinario;
          this.verbale.certCE = macchinario.certCE;
          this.verbale.comuneDitta = macchinario.comuneDitta;
          this.verbale.ditta = macchinario.ditta;
          this.verbale.marca = macchinario.costruttore;
          this.verbale.matricola = macchinario.matricolaInail;
          this.verbale.nFab = macchinario.nFab;
          //this.verbale.numeroCivicoDitta = macchinario.numeroCivicoDitta;
          this.verbale.viaDitta = macchinario.viaDitta;
          this.verbale.luogoVerifica = macchinario.comuneDitta;
          this.verbale.tipoMacchinario = macchinario.tipoAttrezzatura;
        });
      this.verbale.c_bpartner_id = this.$store.state.datiVerbale.c_bpartner_id;
      this.verbale.fct_scadverifiche_id =
        this.$store.state.datiVerbale.fct_scadverifiche_id;
      this.verbale.c_orderline_id =
        this.$store.state.datiVerbale.c_orderline_id;
    }
    this.$store.commit("setProgressMessage", null);
  },
  watch: {
    numeroScheda() {
      if (this.numeroScheda == this.schedeTotali && this.firmaDatore != "") {
        var that = this;
        setTimeout(function () {
          that.$refs.firmaDatore.fromDataURL(
            "data:image/png;base64," + that.firmaDatore
          );
        }, 10);
      }
    },
  },
};
</script>

<style>
</style>