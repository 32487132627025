<template>
    <v-container fluid>
      <v-flex d-flex justify-center>
        <h2>Ricerca verbale</h2>
      </v-flex>
      <v-tabs :icons-and-text="true" v-model="tab">
        <v-tab>
          Ricerca per cliente
          <v-icon>mdi-account</v-icon>
        </v-tab>
        <v-tab>
          Ricerca per dati attrezzatura
          <v-icon>mdi-form-textbox-password</v-icon>
        </v-tab>
      </v-tabs>
      <br />
      <v-expansion-panels v-model="panels">
        <v-expansion-panel v-if="tab == 0">
          <v-expansion-panel-header>
            <h4>Cliente selezionato: {{ cliente.name }}</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-flex>
              <v-autocomplete
                :items="clienti"
                v-model="cliente"
                label="Ricerca cliente"
                hide-no-data
                hide-selected
                item-text="name"
                return-object
                :search-input.sync="ricercaCliente"
                @input="getListaOrdiniCliente"
                outlined
                :disabled="ricercaClienteDisabled"
                ref="ricercaCliente"
              ></v-autocomplete>
              <v-flex d-flex>
                <v-spacer></v-spacer>
                <v-switch
                  label="Mostra ordini già fatturati"
                  v-model="mostraOrdini"
                  id="mostraFatturati"
                ></v-switch>
              </v-flex>
              <v-flex v-if="listaTestaOrdini.length > 0">
                <v-data-table
                  :items="listaTestaOrdini"
                  :headers="headersTestaOrdini"
                  :items-per-page="5"
                  @click:row="getRigheOrdine"
                  no-data-text="Nessun macchinario"
                ></v-data-table>
              </v-flex>
            </v-flex>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="tab == 1">
          <v-expansion-panel-header>
            <h4>Ricerca Attrezzatura</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-flex>
              <v-flex>
                <v-text-field
                  ref="ricercaNFab"
                  label="Numero Fabbrica"
                  outlined
                  v-model="ricercaNFab"
                  id="nfab"
                  @keyup.enter="getListaOrdini()"
                ></v-text-field>
                <v-text-field
                  id="costruttore"
                  ref="costruttore"
                  label="Costruttore"
                  outlined
                  v-model="ricercaCostruttore"
                  @keyup.enter="getListaOrdini()"
                ></v-text-field>
              </v-flex>
              <v-flex d-flex>
                <v-btn
                  id="ricerca"
                  color="success"
                  @click="getListaOrdini()"
                  :disabled="ricercaNFab == '' && ricercaCostruttore == ''"
                  >Ricerca</v-btn
                >
                <v-spacer></v-spacer>
                <span id="mostraFatturati">
                  <v-switch
                    label="Mostra ordini già fatturati"
                    v-model="mostraOrdini"
                  ></v-switch>
                </span>
              </v-flex>
            </v-flex>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="listaOrdini.length > 0 && tab == 1">
          <v-expansion-panel-header>
            <h4>Lista Ordini</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-flex>
              <v-data-table
                :headers="headersOrdini"
                :items="listaOrdini"
                @click:row="selezionaOrdine"
                no-data-text="Nessun ordine"
              ></v-data-table>
            </v-flex>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="tab == 0 && listaOrdini.length > 0">
          <v-expansion-panel-header>
            <h4>Lista Ordini</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-flex>
              <v-data-table
                :headers="headersRigheOrdini"
                :items="listaOrdini"
                @click:row="selezionaRigaOrdine"
                no-data-text="Nessun ordine"
              ></v-data-table>
            </v-flex>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="ordine != null">
          <v-expansion-panel-header>
            <h4>Lista verbali Attrezzatura</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :items="listaVerbali"
              :headers="headersVerbali"
              no-data-text="Nessun verbale"
            >
              <template v-slot:top>
                <v-flex d-flex>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="warning"
                    @click="nuovoVerbaleDismissione"
                    id="dismissione"
                  >
                    <v-icon>mdi-file-remove</v-icon>Dismissione Attrezzatura
                  </v-btn>
                  <v-btn
                    text
                    color="secondary"
                    @click="nuovoVerbaleFuniCatene"
                    id="funiCatene"
                  >
                    <v-icon>mdi-link-variant-plus</v-icon>Funi e Catene
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="nuovoVerbale"
                    id="nuovoVerbale"
                  >
                    <v-icon>mdi-newspaper-plus</v-icon>Nuovo Verbale
                  </v-btn>
                </v-flex>
              </template>
              <template v-slot:item.dataVerbale="{ item }">{{
                item.dataVerbale.substr(0, 10)
              }}</template>
              <template v-slot:item.dataProssimaVerifica="{ item }">{{
                item.dataProssimaVerifica.substr(0, 10)
              }}</template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom pa-5>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="copiaVerbale(item)" icon>
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copia verbale</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="isModificaDisable(item.dataVerbale)"
                      v-on="on"
                      @click="modificaVerbale(item)"
                      icon
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica Verbale</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="downloadVerbale(item)" icon>
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Scarica Verbale</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <br />
      <v-btn color="error" @click="resetRicerca">Reset ricerca</v-btn>
      <v-footer fixed app>
        {{ buildNo }}
      </v-footer>
    </v-container>
  </template>
  
  <script>
  /*eslint-disable */
  import { Conf } from "../conf/defaults";
  import mixin from "../mixin/MixingCommonComp";
  
  export default {
    mixins: [mixin],
    data() {
      return {
        awaitingSearch: false,
        cercaVerbale: "",
        tab: 1,
        panels: 0,
        ricercaCliente: "",
        ricercaNFab: "",
        ricercaCostruttore: "",
        ricercaAzienda: "",
        ricercaClienteDisabled: false,
        mostraOrdini: false,
        cliente: {},
        clienti: [],
        listaTestaOrdini: [],
        macchinario: null,
        ordine: null,
        listaVerbali: [],
        listaOrdini: [],
        headersTestaOrdini: [
          {
            text: "Numero Ordine",
            value: "documentNo",
          },
          {
            text: "Data Ordine",
            value: "dateOrdered",
          },
        ],
        headersVerbali: [
          {
            text: "Nome",
            value: "nomeVerbale",
          },
          {
            text: "Data esecuzione verbale",
            value: "dataVerbale",
          },
          {
            text: "Data Prossima verifica",
            value: "dataProssimaVerifica",
          },
          {
            text: "Azioni",
            value: "actions",
          },
        ],
        headersOrdini: [
          {
            text: "Numero Ordine",
            value: "documentNo",
          },
          {
            text: "Data Ordine",
            value: "dateOrdered",
          },
          {
            text: "Cliente",
            value: "name",
          },
          {
            text: "Numero Fabbrica",
            value: "nFab",
          },
          {
            text: "Costruttore",
            value: "costruttore",
          },
          {
            text: "Tipo verifica",
            value: "tipoVerifica",
          },
        ],
        headersRigheOrdini: [
          {
            text: "Matricola inail",
            value: "matricolaInail",
          },
          {
            text: "Numero Fabbrica",
            value: "nFab",
          },
          {
            text: "Costruttore",
            value: "costruttore",
          },
          {
            text: "Tipo verifica",
            value: "tipoVerifica",
          },
        ],
      };
    },
    methods: {
      resetRicerca() {
        this.$store.commit("setDatiVerbale", null);
        this.panels = 0;
        this.macchinario = null;
        this.ordine = null;
        this.listaVerbali = [];
        this.listaOrdini = [];
        this.listaTestaOrdini = [];
        this.mostraOrdini = false;
        if (this.tab == 0) {
          this.resetCliente();
        } else {
          this.resetMatricola();
        }
      },
      resetCliente() {
        this.ricercaClienteDisabled = false;
        this.cliente = {};
        var that = this;
        setTimeout(function () {
          that.$refs.ricercaCliente.focus();
        }, 50);
      },
      resetMatricola() {
        var that = this;
        this.ricercaNFab = "";
        this.ricercaCostruttore = "";
        setTimeout(function () {
          that.$refs.ricercaNFab.focus();
        }, 50);
      },
      getListaMacchinari() {
        this.ricercaClienteDisabled = true;
        this.$store.commit("setProgressMessage", "Ricerca macchinari");
        this.$store.commit("setProgressLoading", true);
        let data = {};
        data.c_bpartner_id = this.cliente.c_bpartner_id;
        let parameters = {};
        parameters.data = data;
        parameters.idempiereRestPath = "vecos/getListaMacchinari";
  
        this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
          this.listaMacchinari = response.data.listaMacchinari;
          this.$store.commit("setProgressMessage", null);
        });
      },
      getListaOrdiniCliente() {
        this.ricercaClienteDisabled = true;
        this.$store.commit("setProgressMessage", "Ricerca ordini");
        this.$store.commit("setProgressLoading", true);
        let data = {};
        data.c_bpartner_id = this.cliente.c_bpartner_id;
        data.mostraOrdiniFatturati = this.mostraOrdini;
        let parameters = {};
        parameters.data = data;
        parameters.idempiereRestPath = "seritec/getListaOrdiniCliente";
        this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
          this.listaTestaOrdini = response.data.ordini;
          this.$store.commit("setProgressMessage", null);
        });
      },
      getListaOrdini(value) {
        let data = {};
        data.costruttore = this.ricercaCostruttore;
        data.nFab = this.ricercaNFab;
        data.mostraOrdiniFatturati = this.mostraOrdini;
        delete data.tipoVerbale;
        let parameters = {};
        parameters.data = data;
        parameters.idempiereRestPath = "seritec/getListaOrdini";
        this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
          console.log(response);
          if(response.data.result=="OK"){
            this.listaOrdini = response.data.listaOrdini;
            let value = {};
            this.panels = 1;
          }else{
            this.showErrorDialog(response.data.message)
          }
        });
      },
      getRigheOrdine(value) {
        let data = {};
        data.c_order_id = value.c_order_id;
        data.mostraOrdiniFatturati = this.mostraOrdini;
        let parameters = {};
        parameters.data = data;
        parameters.idempiereRestPath = "seritec/getRigheOrdine";
        this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
          console.log(response);
          this.listaOrdini = response.data.righeOrdine;
          this.panels = 1;
        });
      },
      getListaVerbali(value) {
        //this.macchinario = value;
        let data = {};
        data.fct_scadverifiche_id = value.fct_scadverifiche_id;
        let parameters = {};
        parameters.data = data;
        parameters.idempiereRestPath = "seritec/getListaVerbaliFromId";
        this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
          this.listaVerbali = response.data.verbali;
          this.panels = 2;
        });
      },
      selezionaOrdine(value) {
        this.ordine = value;
        if (this.macchinario == null) {
          let data = {};
          data.fct_scadverifiche_id = value.fct_scadverifiche_id;
          let parameters = {};
          parameters.data = data;
          parameters.idempiereRestPath = "seritec/getDatiMacchina";
          this.$store
            .dispatch("callIdempiereRest", parameters)
            .then((response) => {
              if(response.data.result=="OK"){
              let macchinario = {};
              console.log(response);
              macchinario.matricolainail =
                response.data.macchinario.matricolaInail;
              macchinario.costruttore = response.data.macchinario.costruttore;
              macchinario.modello = response.data.macchinario.modello;
              macchinario.tipoVerbale = response.data.macchinario.tipoVerbale;
              macchinario.fct_scadverifiche_id = value.fct_scadverifiche_id;
              let cliente = {};
              cliente.c_bpartner_id = response.data.macchinario.c_bpartner_id;
              cliente.isBadPayer = response.data.macchinario.isBadPayer;
              this.cliente = cliente;
              this.macchinario = macchinario;
              this.getListaVerbali(macchinario);
              }else{
                this.showErrorDialog(response.data.message)
              }
            });
        } else {
          this.getListaVerbali(this.macchinario);
        }
      },
      selezionaRigaOrdine(value) {
        this.ordine = {
          c_orderline_id: value.c_orderline_id,
        };
        this.macchinario = {
          matricolainail: value.matricolainail,
          tipoVerbale: value.tipoVerbale,
          fct_scadverifiche_id: value.fct_scadverifiche_id,
          costruttore: value.costruttore,
          modello: value.modello,
        };
        this.getListaVerbali(this.macchinario);
      },
      nuovoVerbale() {
        this.salvaRicerca();
        let datiVerbale = {
          c_bpartner_id: this.cliente.c_bpartner_id,
          fct_scadverifiche_id: this.macchinario.fct_scadverifiche_id,
          c_orderline_id: this.ordine.c_orderline_id,
          isBadPayer:this.cliente.isBadPayer,
          type: "N",
        };
        datiVerbale[
          "fct_ver" + this.macchinario.tipoVerbale.toLowerCase() + "_id"
        ] = 0;
        this.$store.commit("setDatiVerbale", datiVerbale);
        let path = "verbale" + this.macchinario.tipoVerbale;
        this.$router.push({ path: path });
      },
      copiaVerbale(verbale) {
        this.salvaRicerca();
        let datiVerbale = {
          c_bpartner_id: this.cliente.c_bpartner_id,
          fct_scadverifiche_id: this.macchinario.fct_scadverifiche_id,
          c_orderline_id: this.ordine.c_orderline_id,
          isBadPayer:this.cliente.isBadPayer,
          type: "C",
        };
        datiVerbale["fct_ver" + verbale.tipoVerbale.toLowerCase() + "_id"] =
          verbale.fct_ver_id;
        this.$store.commit("setDatiVerbale", datiVerbale);
        let path = "verbale" + verbale.tipoVerbale;
        this.$router.push({ path: path });
      },
      modificaVerbale(verbale) {
        this.salvaRicerca();
        let datiVerbale = {
          c_bpartner_id: this.cliente.c_bpartner_id,
          fct_scadverifiche_id: this.macchinario.fct_scadverifiche_id,
          c_orderline_id: this.ordine.c_orderline_id,
          isBadPayer:this.cliente.isBadPayer,
          type: "M",
        };
        datiVerbale["fct_ver" + verbale.tipoVerbale.toLowerCase() + "_id"] =
          verbale.fct_ver_id;
        this.$store.commit("setDatiVerbale", datiVerbale);
        let path = "verbale" + verbale.tipoVerbale;
        this.$router.push({ path: path });
      },
      downloadVerbale(item) {
        console.log("downloadVerbale");
        this.$store.commit("setProgressMessage", "Download in corso");
        this.$store.commit("setProgressLoading", true);
        let parameters = {};
        parameters.idempiereRestPath = "print/addPrintJob";
        let data = {};
        data.parameters = "{id:" + item.fct_ver_id + "}";
        console.log(item.nomeStampa);
        data.nomeStampa = item.nomeStampa;
        parameters.data = data;
        this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
          if (response.data.hasOwnProperty("file")) {
            const linkSource =
              "data:application/pdf;base64," + response.data.file;
            const downloadLink = document.createElement("a");
            const fileName = item.nomeVerbale + ".pdf";
  
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            this.$store.commit("setProgressMessage", null);
          } else {
            this.$store.commit("setProgressMessage", null);
            var errorMsg = "Download non riuscito";
            if (response.data.hasOwnProperty("message")) {
              errorMsg = errorMsg + "\n" + response.data.message;
            }
            this.$store.commit("setErrorMessage", errorMsg);
          }
        });
      },
      getMacchinario() {
        var that = this;
        if (that.ricercaNFab == null || that.ricercaNFab.length < 0) {
          return;
        }
        that.$store.commit("setProgressMessage", "Ricerca macchinari");
        that.$store.commit("setProgressLoading", true);
        let data = {};
        data.matricolainail = that.ricercaMatricola;
        let parameters = {};
        parameters.data = data;
        parameters.idempiereRestPath = "vecos/getListaVerbaliFromMatricola";
        that.$store.dispatch("callIdempiereRest", parameters).then((response) => {
          that.$store.commit("setProgressMessage", null);
          if (response.data.macchinario.hasOwnProperty("fct_scadverifiche_id")) {
            that.cliente = {
              c_bpartner_id: response.data.macchinario.c_bpartner_id,
            };
            that.macchinario = response.data.macchinario;
            that.getListaOrdini(that.macchinario);
          } else {
            that.$store.commit("setErrorMessage", "Nessun macchinario trovato");
          }
        });
      },
      salvaRicerca() {
        let data = {};
        data.cliente = this.cliente;
        data.ordine = this.ordine;
        data.macchinario = this.macchinario;
        data.listaOrdini = this.listaOrdini;
        data.listaTestaOrdini = this.listaTestaOrdini;
        data.cliente = this.cliente;
        data.tab = this.tab;
        this.$store.commit("setDatiRicerca", data);
      },
      ripristinaRicerca() {
        if (this.$store.state.datiRicerca != null) {
          let dati = this.$store.state.datiRicerca;
          this.tab = dati.tab;
          var that = this;
          setTimeout(function () {
            console.log(that);
            console.log(dati);
            that.macchinario = dati.macchinario;
            that.listaOrdini = dati.listaOrdini;
            that.cliente = dati.cliente;
            that.ordine = dati.ordine;
            that.panels = 2;
            that.listaTestaOrdini = dati.listaTestaOrdini;
            that.getListaVerbali(dati.macchinario);
            that.$store.commit("setDatiRicerca", null);
          }, 100);
        }
      },
      isModificaDisable(dataVerbale) {
        let dataV = new Date(dataVerbale).getTime();
        let dataO = new Date().getTime();
        return !(dataO - dataV < 2678400000 || this.mostraOrdini);
      },
      nuovoVerbaleDismissione() {
        this.salvaRicerca();
        let datiVerbale = {
          c_bpartner_id: this.cliente.c_bpartner_id,
          fct_scadverifiche_id: this.macchinario.fct_scadverifiche_id,
          c_orderline_id: this.ordine.c_orderline_id,
          isBadPayer:this.cliente.isBadPayer,
        };
        datiVerbale[
          "fct_verdismissione" +
            this.macchinario.tipoVerbale.toLowerCase() +
            "_id"
        ] = 0;
        this.$store.commit("setDatiVerbale", datiVerbale);
        //let path = "verbaleDismissione";
        let path = "verbaleDismissione" + this.macchinario.tipoVerbale;
        this.$router.push({ path: path });
      },
      nuovoVerbaleFuniCatene() {
        this.salvaRicerca();
        let datiVerbale = {
          c_bpartner_id: this.cliente.c_bpartner_id,
          fct_scadverifiche_id: this.macchinario.fct_scadverifiche_id,
          c_orderline_id: this.ordine.c_orderline_id,
          isBadPayer:this.cliente.isBadPayer,
        };
        datiVerbale["fct_verfunicatene_id"] = 0;
        this.$store.commit("setDatiVerbale", datiVerbale);
        //let path = "verbaleDismissione";
        let path = "verbaleFuniCatene";
        this.$router.push({ path: path });
      },
    },
    created() {
      this.ripristinaRicerca();
    },
    watch: {
      ricercaCliente: function () {
        if (this.ricercaCliente == null || this.ricercaCliente == "") return;
        if (this.ricercaCliente.length >= 3) {
          let data = {};
          data.name = this.ricercaCliente.toLowerCase();
          data.mostraOrdiniFatturati = this.mostraOrdini;
          let parameters = {};
          parameters.data = data;
          parameters.idempiereRestPath = "vecos/cercaCliente";
          this.$store
            .dispatch("callIdempiereRest", parameters)
            .then((response) => {
              this.clienti = response.data.clienti;
            });
        }
      },
      tab: function (newValue, oldValue) {
        this.resetRicerca();
      },
    },
    computed: {
      buildNo: function () {
        return Conf.buildNo.replaceAll("-", "");
      },
    },
  };
  </script>
  
  <style>
  </style>
  