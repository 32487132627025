<template>
  <v-container>
    <v-flex>
      <h2>Modifica Firma</h2>
      <br />
      <v-text-field label="Firma" v-model="etichettaFirma" outlined></v-text-field>
      <v-text-field label="Iniziali" v-model="inizialiFirma" outlined></v-text-field>
      <VueSignaturePad ref="signature" style="border: 1px solid #6c757d" width="702px" height="180px"/>
    </v-flex>
    <br />
    <v-flex d-flex>
      <v-btn color="error" @click="cancellaFirma">Cancella firma</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="salvaFirma" :disabled="saveDisable">Salva firma</v-btn>
    </v-flex>
  </v-container>
</template>

<script>
/*eslint-disable*/
import VueSignaturePad from "vue-signature-pad";
import MixingCommonComp from "../mixin/MixingCommonComp";

export default {
  mixins: [MixingCommonComp],
  data() {
    return {
      saveDisable: true,
      etichettaFirma: "",
      inizialiFirma: ""
    };
  },
  methods: {
    cancellaFirma() {
      this.$refs.signature.clearSignature();
    },
    salvaFirma() {
      if (this.$refs.signature.saveSignature().data == null) {
        var errorMsg = "La firma non può essere vuota";
        this.$store.commit("setErrorMessage", errorMsg);
        this.saveDisable = false;
        return;
      }
      this.saveDisable = true;
      this.$store.commit("setProgressMessage", "Salvataggio in corso");
      let parameters = {};
      let data = {};
      data.firmaBase64 = this.$refs.signature
        .saveSignature()
        .data.split(",")[1];
      data.ad_user_id = this.$session.get("ad_user_id");
      data.etichettaFirma = this.etichettaFirma;
      data.inizialiFirma = this.inizialiFirma;
      parameters.data = data;
      parameters.idempiereRestPath = "vecos/updateFirmaUser";
      this.$store.dispatch("callIdempiereRest", parameters).then(response => {
        if ((response.data.result = "OK")) {
          this.$store.commit("setProgressMessage", "Salvataggio riuscito");
          this.$store.commit("setProgressLoading", false);
          this.savedisabled = false;
          var that = this;
          setTimeout(function() {
            that.$store.commit("setProgressMessage", null);
            that.$store.commit("setProgressLoading", true);
          }, 1000);
        } else {
          this.$store.commit("setProgressMessage", null);
          var errorMsg = "Salvataggio non riuscito";
          if (response.data.hasOwnProperty("message")) {
            errorMsg = errorMsg + "\n" + response.data.message;
          }
          this.$store.commit("setErrorMessage", errorMsg);
        }
      });
    }
  },
  created() {
    let parameters = {};
    parameters.data = {};
    parameters.data.ad_user_id = this.$session.get("ad_user_id");
    parameters.idempiereRestPath = "vecos/getFirma";
    this.$store.dispatch("callIdempiereRest", parameters).then(response => {
      this.$refs.signature.fromDataURL(
        "data:image/png;base64," + response.data.firmaBase64
      );
      this.etichettaFirma = response.data.etichettaFirma;
      this.inizialiFirma = response.data.inizialiFirma;
      this.saveDisable = false;
    });
  }
};
</script>

<style>
</style>