<template>
  <v-container>
    <h2>Verbali Recenti</h2>
    <v-flex d-flex xs12>
      <v-data-table :items="listaVerbali" :headers="headersVerbali">
        <template v-slot:item.actions="{item}">
          <v-tooltip bottom pa-5>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="copiaVerbale(item)" icon>
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Copia verbale</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="isModificaDisable(item.data)"
                v-on="on"
                @click="modificaVerbale(item)"
                icon
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Modifica Verbale</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="downloadVerbale(item)" icon>
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Scarica Verbale</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-flex>
  </v-container>
</template>

<script>
/*eslint-disable */
import MixingCommonComp from '../mixin/MixingCommonComp';
export default {
  mixins: [MixingCommonComp],
  data() {
    return {
      headersVerbali: [
        { text: "Cliente", value: "cliente" },
        { text: "Numero Ordine", value: "documentno" },
        { text: "Matricola Inail", value: "matricolaInail" },
        { text: "Costruttore", value: "costruttore" },
        { text: "Tipo Verbale", value: "tipoVerbale" },
        { text: "Data", value: "data" },
        { text: "Azioni", value: "actions" },
      ],
      listaVerbali: [],
    };
  },
  methods: {
    isModificaDisable(dataVerbale) {

      //let dataV = new Date(dataVerbale).getTime();
      //let dataO = new Date().getTime();
      //return dataO - dataV > 2678400000;

      //disattivazione controllo come da richiesta del cliente del 10/09/2020
      return false;
    },
    downloadVerbale(item) {
      console.log(item.nomeStampa);
      this.$store.commit("setProgressMessage", "Download in corso");
      this.$store.commit("setProgressLoading", true);
      let parameters = {};
      parameters.idempiereRestPath = "print/addPrintJob";
      let data = {};
      data.parameters = "{id:" + item.verid + "}";
      data.nomeStampa = item.nomeStampa
      parameters.data = data;
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.hasOwnProperty("file")) {
          const linkSource =
            "data:application/pdf;base64," + response.data.file;
          const downloadLink = document.createElement("a");
          const fileName = item.nomeVerbale + ".pdf";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.$store.commit("setProgressMessage", null);
        } else {
          this.$store.commit("setProgressMessage", null);
          var errorMsg = "Download non riuscito";
          if (response.data.hasOwnProperty("message")) {
            errorMsg = errorMsg + "\n" + response.data.message;
          }
          this.$store.commit("setErrorMessage", errorMsg);
        }
      });
    },
    copiaVerbale(verbale) {
      let datiVerbale = {
        c_bpartner_id: verbale.c_bpartner_id,
        fct_scadverifiche_id: verbale.fct_scadverifiche_id,
        c_orderline_id: verbale.c_orderline_id,
        type: "C",
      };
      datiVerbale["fct_ver" + verbale.tipoVerbale.toLowerCase().replace(" ","") + "_id"] =
        verbale.verid;
      this.$store.commit("setDatiVerbale", datiVerbale);
      console.log(datiVerbale)
      let path = "verbale" + verbale.tipoVerbale.toLowerCase().replace(" ","").replace("tecnico","");
      this.$router.push({ path: path });
    },
    modificaVerbale(verbale) {
      let datiVerbale = {
        c_bpartner_id: verbale.c_bpartner_id,
        fct_scadverifiche_id: verbale.fct_scadverifiche_id,
        c_orderline_id: verbale.c_orderline_id,
        type: "M",
      };
      datiVerbale["fct_ver" + verbale.tipoVerbale.toLowerCase() + "_id"] =
        verbale.verid;
      this.$store.commit("setDatiVerbale", datiVerbale);
      let path = "verbale" + verbale.tipoVerbale;
      this.$router.push({ path: path });
    },
  },
  mounted() {
    let parameters = {};
    parameters.data = {};
    parameters.idempiereRestPath = "seritec/getVerbaliRecenti";
    this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
      this.listaVerbali = response.data.verbali;
    });
  },
};
</script>