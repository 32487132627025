<template>
  <span>
    <v-text-field
      v-model="time"
      :label="label"
      append-icon="mdi-clock"
      :prepend-icon="prependIcon"
      :outlined="outlined"
      :readonly="readonly"
      :dense="dense"
      :disabled="disabled"
      :hide-details="hideDetails"
      @click:append="modal = true"
      :rules="[rules.timeValid]"
    >
    </v-text-field>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="time"
      persistent
      width="290px"
    >
      <v-time-picker
        v-if="modal"
        v-model="time"
        full-width
        :format="format"
        :allowed-hours="allowedHours"
        :allowed-minutes="allowedMinutes"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(time)">
          OK
        </v-btn>
      </v-time-picker></v-dialog
    >
  </span>
</template>

<script>
export default {
  props: {
    value: { type: String, default: "00:00" },
    label: { type: String, default: "" },
    outlined: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    format: { type: String, default: "ampm" },
    prependIcon: { type: String, default: "" },
    prependInnerIcon: { type: String, default: "" },
    allowedHours: { type: Function, default: (h) => h >= 0 },
    allowedMinutes: { type: Function, default: (m) => m >= 0 },
    allowedStep: { type: Function },
    type: { type: String, default: "menu" },
    dense: { type: Boolean, default: false },
    disabled: { type: Boolean },
    hideDetails: { type: Boolean, default: false },
  },
  data() {
    return {
      time: "00:00",
      modal: false,
      rules: {
        timeValid: (val) => !!this.validateTime(val),
      },
    };
  },
  methods: {
    validateTime(val) {
      let time = val.split(":");

      if (time.length != 2) return false;

      let hours = parseInt(time[0]);
      let minutes = parseInt(time[1]);

      if (!hours || hours < 0 || hours >= 24) return false;
      if (minutes==null || isNaN(minutes) || minutes < 0 || minutes >= 60) return false;

      return true;
    },
  },
  mounted() {
    this.time = this.value;
  },
  watch: {
    time: function (val) {
      if (this.validateTime(val)) {
        this.$emit("input", val);
      }
    },
    value: function (val) {
      this.time = val;
    },
  },
};
</script>

<style>
</style>