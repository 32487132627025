<template>
  <v-container>
    <h2>Esportazione Verbali</h2>
    <br />
    <v-flex d-flex>
      <v-text-field dense outlined label="Anno" v-model="year"></v-text-field>
      <v-btn
        color="success"
        :disabled="exportDisable"
        @click="getVerbaliPerAnno"
        >Esporta</v-btn
      >
    </v-flex>
  </v-container>
</template>

<script>
/*eslint-disable */
import mixin from "../mixin/MixingCommonComp";

var XLSX = require("xlsx");

export default {
  mixins: [mixin],
  data() {
    return {
      year: new Date().toISOString().substring(0, 4),
    };
  },
  methods: {
    getVerbaliPerAnno() {
      this.showLoadingDialog(true,"Export in corso..");
      let parameters = {};
      parameters.data = {
        anno: this.year,
      };
      parameters.idempiereRestPath = "seritec/getVerbaliPerAnno";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.verbali.length > 0) {
          this.exportExcel(response.data.verbali);
           this.showLoadingDialog(false);
        } else {
          this.showErrorDialog("Nessun verbale effettuato nell'anno indicato");
        }
      });
    },
    exportExcel(verbali) {
      var worksheet = {};
      var range = {
        s: { r: 0, c: 0 },
        e: { r: 5000, c: 10 },
      }; //cella di start e cella end

      let i = 1;

      worksheet["A" + i] = { t: "s", v: "Nome Verbale" };
      worksheet["B" + i] = { t: "s", v: "Nome Cognome Verificatore" };
      worksheet["C" + i] = { t: "s", v: "Data" };
      worksheet["D" + i] = { t: "s", v: "Tipo" };

      for (let verbale of verbali) {
        i++;
        worksheet["A" + i] = { t: "s", v: verbale.nomeVerbale };
        worksheet["B" + i] = { t: "s", v: verbale.etichettafirma };
        worksheet["C" + i] = {
          t: "s",
          v: this.dateToItalianFormat(verbale.data),
        };
        worksheet["D" + i] = { t: "s", v: verbale.tipoVerbale };
      }

      worksheet["!ref"] = XLSX.utils.encode_range(range);
      var wb = {
        SheetNames: ["Verbali"],
        Sheets: {
          Verbali: worksheet,
        },
      };

      let filename = "verbali_" + this.year + ".xlsx";

      XLSX.writeFile(wb, filename);
    },
  },
  computed: {
    exportDisable() {
      let year = parseInt(this.year);
      if (isNaN(year)) return true;
      if (year < 1990) return true;
      return false;
    },
  },
};
</script>

<style>
</style>