<template>
  <v-container fluid>
    <span v-if="window">
      <Tab
        v-model="record_id"
        :ad_tab_id="topTab.AD_Tab_ID"
        :language="language"
        ref="tab"
        v-on:save="(success)=>{$emit('save',success)}"
        v-on:delete="(success)=>{$emit('delete',success)}"
        v-on:copy="(success)=>{$emit('copy',success)}"
        v-on:change="(value)=>{$emit('change', value)}"
        v-on:datafetched="(value)=>{$emit('datafetched', value)}"
        :hide-save="hideSave"
        :hide-tabs="hideTabs"
        :hide-delete="hideDelete"
        :hide-copy="hideCopy"
      ></Tab>
    </span>
  </v-container>
</template>

<script>
/*eslint-disable */
import Tab from "./Tab.vue";
import TabGridTable from "./TabGridTable.vue";

export default {
  name: "Window",
  components: { Tab, TabGridTable },
  props: {
    value: { type: Number, default: 0 },
    ad_window_id: { type: Number, default: 0 },
    name: { type: String, default: null },
    language: { type: String, default: "it_IT" },
    hideSave: { type: Boolean, default: false },
    hideTabs: { type: Boolean, default: false },
    hideDelete: { type: Boolean, default: false },
    hideCopy: { type: Boolean, default: false },
  },
  data() {
    return {
      window: null,
      tabs: 0,
    };
  },
  methods: {
    getWindow() {
      let parameters = {};
      if (this.ad_window_id > 0) {
        parameters.data = { ad_window_id: this.ad_window_id };
      } else {
        parameters.data = { name: this.name };
      }
      parameters.idempiereRestPath = "window/getWindow";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.window = response.data.Window;
      });
    },
    setValue(name, value) {
      if(this.$refs !=null && this.$refs.tab!=null && this.$refs.tab.dataFetched){
        this.$refs.tab.setValue(name, value);
      }else{
        setTimeout(this.setValue, 1000, name, value);
      }
    },
    setCopyValue(name, value) {
      if(this.$refs !=null && this.$refs.tab!=null){
        this.$refs.tab.setCopyValue(name, value);
      }else{
        setTimeout(this.setCopyValue, 1000, name, value);
      }
    },
    getData(){
      if(this.$refs !=null && this.$refs.tab!=null){
        this.$refs.tab.getData()
      }else{
        setTimeout(this.getData, 1000);
      }
    },
    getValue(name){
      if(this.$refs !=null && this.$refs.tab!=null){
        return this.$refs.tab.getValue(name)
      }else{
        setTimeout(this.getValue, 1000,name);
      }
    },
    copyRecord(){
      if(this.$refs !=null && this.$refs.tab!=null){
        this.$refs.tab.copyRecord();
      }else{
        setTimeout(this.copyRecord, 1000);
      }
    }
  },
  mounted() {
    this.getWindow();
  },
  computed: {
    record_id: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    topTab() {
      if (!this.window) return {};
      for (let tab of this.window.Tabs) {
        if (tab.TabLevel == 0) return tab;
      }
      return null;
    },
  },
  watch: {
    ad_window_id: function () {
      this.getWindow();
    },
    name: function () {
      this.getWindow();
    },
  },
};
</script>

<style>
</style>