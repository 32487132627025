<template>
    <v-container fluid>

        <v-flex d-flex>
            <v-spacer></v-spacer>
            <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-16 mb-2" text v-bind="attrs" v-on="on" v-if="calendarType == 'month'">
                        <h4>
                            {{ month }}
                        </h4>
                    </v-btn>

                </template>
                <v-date-picker v-model="date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(date); getEvents()">
                        OK
                    </v-btn>
                </v-date-picker>
            </v-dialog>
            <v-spacer></v-spacer>
        </v-flex>

        <v-row>
            <v-col>
                <v-btn text @click="getEvents"><v-icon>mdi-refresh</v-icon>Aggiorna</v-btn>
            </v-col>
            <v-col>
                <v-btn class="ml-n2" icon @click="prev">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn icon @click="next">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>



        </v-row>

        <v-row class="mt-3 mb-4">
            <v-col cols="2" v-if="!hideCalendars" >
                <v-flex rounded class="calendar-border">
                    <v-checkbox label="Tutti" class="minified-checkbox" hide-details v-model="selectAll"></v-checkbox>
                    <v-checkbox v-for="calendar in calendars" :key="calendar.ad_user_id" v-model="selectedCalendars"
                        :value="calendar" :label="calendar.name" class="minified-checkbox" hide-details
                        :color="calendar.color">
                    </v-checkbox>
                </v-flex>
            </v-col>
            <v-col :cols="calendarCols" style="height: 100%">
                <v-calendar :type="calendarType" :weekdays="[1, 2, 3, 4, 5, 6]" :events="filteredEvents" ref="calendar"
                    v-model="date" id="calendar" class="noselect" :event-color="getColor" :event-more="false"
                    @mousedown:time="startTime" @mousedown:day="startTime" @mousedown:event="startDrag"
                    @mouseup:time="endDrag" @mouseup:day="endDrag" @mouseleave.native="cancelDrag">

                    <template v-slot:event="{ event, timed, timeSummary }">
                        <b>{{ event.name }}</b>, {{ timeSummary() }}, {{ event.username }}

                    </template>
                    
                </v-calendar>              
            </v-col>
        </v-row>
        <Event ref="event"></Event>
    </v-container>

</template>

<script>
/* eslint-disable */
import colors from "../components/colors";
import Event from '../components/Event.vue';
export default {
    components: { Event },
    data() {
        return {
            calendarTypes: [
                { name: "Mensile", value: "month" },
                { name: "Settimanale", value: "week" },
                { name: "Giornaliero", value: "day" },
            ],
            calendarType: "month",
            events: [],
            date: new Date().toISOString().substring(0, 10),
            calendars: [],
            selectedCalendars: [],
            originalEvent: null,
            dragEvent: null,
            dragged: false,
            dragStart: null,
            createEvent: null,
            createStart: null,
            extendOriginal: null,
            newEvent: false,
            value: "",
            modal: false,
        };
    },
    props: {
        
        name: "Nuova Attività",
        ad_user_id: { type: Number},
        users: { type: Array },
        selectedUsers: { type: Array},
        projects: { type: Array},
        selectedProjects: { type: Array},
        c_bpartner_id: { type: Number },
        bpname: { type: String},
        bpvalue: { type: String}
    },

    methods: {
        next() {
            this.$refs.calendar.next();
            this.getEvents();
        },
        prev() {
            this.$refs.calendar.prev();
            this.getEvents();
        },
        getEvents() {
            let dateStart = new Date(this.date);
            dateStart.setDate(-30);
            let dateEnd = new Date(this.date);
            dateEnd.setDate(dateEnd.getDate() + 50);
            let parameters = {};
            parameters.data = {
                dateStart: dateStart,
                dateEnd: dateEnd,
            };
            parameters.idempiereRestPath = "vecos/getEvents";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.events = response.data.events;
                /*for(let i in this.events){
                  this.events[i].start = new Date(this.events[i].start).toISOString().replace("T"," ").substring(0, 19)
                  this.events[i].end = new Date(this.events[i].end).toISOString().replace("T"," ").substring(0, 19)
      
                }*/
                console.log(this.events)
            });
        },
        getCalendars() {
            let parameters = {};
            parameters.data = {};
            parameters.idempiereRestPath = "vecos/getCalendars";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.calendars = response.data.calendars;
                this.calendars.forEach((element, index) => {
                    element.color = colors[index];
                });
                this.selectedCalendars = this.calendars;
            });
        },
        getColor(event) {
            let calendar = this.calendars.find((item) => {
                return item.ad_user_id == event.ad_user_id;
            });
            return calendar ? calendar.color : "primary";
        },
        startDrag({ event }) {
            if (event) {
                this.originalEvent = { ...event };
                this.dragEvent = event;
                this.dragTime = null;
                this.extendOriginal = null;
            }
        },
        startTime(tms) {
            const mouse = this.toTime(tms);

            if (this.dragEvent && this.dragTime === null) {
                const start = this.dragEvent.start;

                this.dragTime = mouse - start;
            } else {
                this.newEvent = true;
                this.createStart = this.roundTime(mouse);
                /*this.createEvent = {
                    name: `Creazione attività #${this.events.length}`,
                    start: this.createStart,
                    end: this.createStart,
                    timed: true,
                    username: "",
                    ad_user_id: -1,
                    users: [],
                    projects: [],
                    a_asset_id: 0,
                };*/  
                

                this.createEvent = {
                name: `Nuova Attività`,
                start: this.createStart,
                end: this.createStart,
                timed: true,
                username: "",
   
                ad_user_id: this.ad_user_id,
                users: this.users,
                
                selectedUsers: this.users,
                c_bpartner_id: this.c_bpartner_id,
                bpname: this.bpname,
                bpvalue: this.bpvalue,
                projects: [],
                selectedProjects: this.projects,
                a_asset_id: 0,
                    /*
                projects: [],
                selectedProjects: projects,
                a_asset_id: 0,
                c_bpartner_id: this.c_bpartner_id,
                bpname: this.bpname,
                bpvalue: this.bpvalue,*/
            }

            this.events.push(this.createEvent);
            }
        },
        async endDrag() {
            if (
                this.dragEvent &&
                (this.dragEvent.start != this.originalEvent.start ||
                    this.dragEvent.end != this.originalEvent.end)
            ) {
                this.moveEvent(this.dragEvent);
            } else if (this.dragEvent && !this.dragged) {
                await this.$refs.event.open(this.dragEvent);
                this.getEvents();
            } else if (this.newEvent) {
                console.log("createEvent");
                await this.$refs.event.open(this.createEvent);
                this.getEvents();
            } else {
                this.moveEvent(this.createEvent);
            }
            this.dragTime = null;
            this.dragEvent = null;
            this.createEvent = null;
            this.createStart = null;
            this.extendOriginal = null;
            this.originalEvent = null;
            this.dragged = false;
            this.newEvent = false;
        },
        
        cancelDrag() {
            if (this.createEvent) {
                if (this.extendOriginal) {
                    this.createEvent.end = this.extendOriginal;
                } else {
                    const i = this.events.indexOf(this.createEvent);
                    if (i !== -1) {
                        this.events.splice(i, 1);
                    }
                }
            }

            this.createEvent = null;
            this.createStart = null;
            this.dragTime = null;
            this.dragEvent = null;
            this.originalEvent = null;
            this.newEvent = false;
        },
        roundTime(time, down = true) {
            const roundTo = 15; // minutes
            const roundDownTime = roundTo * 60 * 1000;

            return down
                ? time - (time % roundDownTime)
                : time + (roundDownTime - (time % roundDownTime));
        },
        toTime(tms) {
            return new Date(
                tms.year,
                tms.month - 1,
                tms.day,
                tms.hour,
                tms.minute
            ).getTime();
        },
    },

    computed: {
        filteredEvents() {
            let ad_user_ids = this.selectedCalendars.map((x) => x.ad_user_id);
            return this.events.filter((item) => {
                return ad_user_ids.includes(item.ad_user_id) || item.ad_user_id == -1;
            });
        },
        hideCalendars() {
            return this.calendars.length <= 1 || this.$vuetify.breakpoint.smAndDown;
        },
        calendarCols() {
            return this.hideCalendars ? 12 : 10;
        },
        month() {
            let monthNames = [
                "Gennaio",
                "Febbraio",
                "Marzo",
                "Aprile",
                "Maggio",
                "Giugno",
                "Luglio",
                "Agosto",
                "Settembre",
                "Ottobre",
                "Novembre",
                "Dicembre",
            ];
            let date = new Date(this.date);
            let month = monthNames[date.getMonth()];
            let year = date.getFullYear();
            return month + " " + year;
        },
        week() {
            let current = new Date(this.date);
            var first = current.getDate() - current.getDay() + 1; // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            let week =
                "Settimana dal " +
                this.dateToItalianFormat(
                    new Date(current.setDate(first)).toISOString().substring(0, 10)
                );
            week +=
                " al " +
                this.dateToItalianFormat(
                    new Date(current.setDate(last)).toISOString().substring(0, 10)
                );
            return week;
        },
        day() {
            let date = new Date(this.date);
            return this.dateToItalianFormat(date.toISOString().substring(0, 10));
        },
        selectAll: {
            get() {
                for (let calendar of this.calendars) {
                    if (!this.selectedCalendars.includes(calendar)) {
                        return false;
                    }
                }
                return true;
            },
            set() {
                if (this.selectedCalendars.length < this.calendars.length) {
                    this.selectedCalendars = this.calendars;
                } else {
                    this.selectedCalendars = [];
                }
            },
        },
    },

    mounted() {
        this.getCalendars();
        this.getEvents()
    }
}


</script>

<style>
#calendar{
    height: 180%;
}

</style>