import Vue from "vue";
import Vuex from "vuex";
import loginStore from "./loginStore";
import printx from "./printxStore";
import { Conf } from "../conf/defaults";
import axios from "axios";
import router from "../router";
//import createPersistedState from 'vuex-persistedstate'
//import * as Cookies from 'js-cookie'
import VuexPersist from "vuex-persist";

Vue.use(Vuex);
/* eslint-disable */

const vuexPersist = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage // or window.sessionStorage or localForage
  //storage: window.sessionStorage,
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export const store = new Vuex.Store({
  modules: {
    login: loginStore,
    printx
  },
  state: {
    tipoaccount: "", // ADMIN or USER
    currentState: "", // DOC or TICKET
    currentLeftMenu: [],
    //StampaLottoInterno
    //stampaLI_DOTes: null,     //CURRENT DO_Tes, if not set button is disabled
    //stampaLI_Saved: false,    //if current DO_Tes is saved or not
    //Error Message
    error: null,
    errorMessage: null,
    //Work in progress Dialog
    dialogMessage: null,
    //Messages not in a dialog but inside the window
    progressMessage: null,
    //Info dialog message
    infoMessage: null,
    //Se true mostra unn icona di caricamento, se false mostra un spunta id successo
    progressLoading: true,
    //lottoInterno,
    lottoInterno: null,
    //UUID
    uuid: null,
    //BreadCrumbItems
    breadcrumbItems: [],
    //BPartnerId
    cliente: {},
    //Stampe in coda
    printJob: [],
    //Sms in queuedSMSMessages
    queuedSMSMessages: [],
    //Notifiche push
    listaNotifichePush: [],
    //Salvataggio automatico verbale SP
    bozzaSP: null,
    //Salvataggio automatico verbale GVR
    bozzaGVR: null,
    //Dati verbale
    datiVerbale: null,
    //Dati della ricerca inziale dei verbali
    datiRicerca: null,
    //Bozza per autosalvataggio del verbale GVRIR
    bozzaGVRIR: null,
  },
  plugins: [vuexPersist.plugin],
  mutations: {
    setUUID(state, uuid) {
      //console.log("UUID >> " + uuid)
      state.uuid = uuid;
    },
    setError(state, error) {
      state.error = error;
      state.errorMessage = error.message;
    },
    setErrorMessage(state, errorMessage) {
      //console.log(errorMessage)
      state.errorMessage = errorMessage;
    },
    setDialogMessage(state, dialogMessage) {
      state.dialogMessage = dialogMessage;
    },
    setProgressMessage(state, progressMessage) {
      state.progressMessage = progressMessage;
    },
    setInfoMessage(state, infoMessage) {
      state.infoMessage = infoMessage
    },
    setStatus(state, status) {
      var menuDef = new MenuDef();
      if (status == "DOC") {
        state.currentState = status;
        state.currentLeftMenu = menuDef.getMenuList(status);
        //state.currentLeftMenu = state.menuitemsDOC
      } else if (status == "TICKET") {
        state.currentState = status;
        state.currentLeftMenu = menuDef.getMenuList(status);
        //state.currentLeftMenu = state.menuitemsTICKET
      }
    },
    setBreadcrumbItems(state, breadcrumbItems) {
      // console.log("setBreadcrumbItems breadcrumbItems >> " + breadcrumbItems)
      state.breadcrumbItems = breadcrumbItems;
    },
    setProgressLoading(state, progressLoading) {
      state.progressLoading = progressLoading
    },
    setLottoInterno(state, lottoInterno) {
      state.lottoInterno = lottoInterno;
    },
    setCliente(state, cliente) {
      state.cliente = cliente
    },
    setPrintJob(state, printJob) {
      state.printJob = printJob
    },
    setQueuedSMSMessages(state, queuedSMSMessages) {
      state.queuedSMSMessages = queuedSMSMessages
    },
    setListaNotifichePush(state, listaNotifichePush) {
      state.listaNotifichePush = listaNotifichePush
    },
    setBozzaSP(state, bozzaSP) {
      state.bozzaSP = bozzaSP
    },
    setBozzaGVR(state, bozzaGVR) {
      state.bozzaGVR = bozzaGVR
    },
    setDatiVerbale(state, datiVerbale) {
      state.datiVerbale = datiVerbale
    },
    setDatiRicerca(state, datiRicerca) {
      state.datiRicerca = datiRicerca
    },
    setBozzaGVRIR(state,bozzaGVRIR){
      state.bozzaGVRIR=bozzaGVRIR
    }
  },
  actions: {
    //***************************************************
    //
    // Metodo generico per chiamare metodi POST
    //
    // params.urlMethod
    // params.debug
    //

    postAxiosAction({ state, commit, rootState }, params) {
      var self = this;
      //console.log("* postAxiosAction");
      //console.log(params)
      return new Promise((resolve, reject) => {
        var urlPostRequest = Conf.ridproxyUrl + params.urlMethod;
        //console.log(urlPostRequest)
        var risp = {};

        params.uuid = rootState.uuid;
        if (params.webServiceType) {
          params.webServiceType = params.webServiceType;
        }
        var listaDatiFromJson = [];
        //console.log(params.uuid)

        if (!rootState.uuid) {
          router.push({
            name: "login"
          });
        }

        axios.defaults.headers["Content-Type"] =
          "application/json;charset=utf-8";
        axios
          .post(urlPostRequest, JSON.stringify(params), {
            headers: {
              "Content-type": "application/json;charset=utf-8"
            }
          })
          .then(response => {
            if (params.debug) {
              console.log("debug response");
              //console.log(response)
            }

            var listaDatiFromJson = [];

            if (response.data.data != null) {
              risp = response.data.data;

              //console.log(risp)

              for (var i in risp) {
                var riga = risp[i];
                //console.log(riga)

                var linea = {};
                for (var key in riga) {
                  if (params.debug) {
                    //console.log("key")
                    //console.log(key)
                    //console.log(riga[key].value)
                    //console.log("key %s, value %s" % (key, riga[key]))
                  }
                  linea[key] = riga[key].value;
                } //for
                listaDatiFromJson[i] = linea;
              } //for
            } else if (response.data != null) {
              risp = response.data;
              var linea = {};
              var i = 0;
              for (var key in risp) {
                //console.log("key >>> " + key)
                //console.log("value >>> " + risp[key])
                //risp[key] = risp[key].value;
                linea[key] = risp[key];
                listaDatiFromJson[i] = linea;
                i++;
              }
            }

            if (params.debug) {
              //console.log(listaDatiFromJson);
            }
            resolve(listaDatiFromJson);
          }),
          error => {
            console.log("Errore");
            console.log(error);
            state.commit("setErrorMessage", error);
            reject(error);
          };
      }).catch(e => {
        console.log("Eccezione");
        console.log(e);
        //this.errors.push(e)
      });
      //commit('setProgressMessage', "Ricerca completata");
      //commit('setDialogMessage', null);
    },
    getQueryResult({ state, commit, rootState }, params) {
      if (!rootState.uuid) {
        router.push({
          name: "login"
        });
      }
      var self = this;
      console.log("getListaClienti");
      return new Promise((resolve, reject) => {
        var urlPostRequest = Conf.ridproxyUrl + "/pcli/getQueryResult";

        var parameters = {};
        parameters.uuid = rootState.uuid;
        parameters.query = params.query;
        // "SELECT AD_user.ad_user_id from AD_user \
        //where AD_user.Ad_user_id = "
        /*
                var fieldTypes = {}
                fieldTypes = [{
                  "name": "ad_user_id",
                  "type": "s"
                }]
        */
        parameters.fieldTypes = params.fieldTypes;

        console.log(urlPostRequest);

        axios.defaults.headers["Content-Type"] =
          "application/json;charset=utf-8";
        axios
          .post(
            urlPostRequest,
            //parameters,
            JSON.stringify(parameters), // the data to post
            {
              headers: {
                "Content-type": "application/json;charset=utf-8"
              }
            }
          )
          .then(response => {
            console.log(response);
            resolve(response);
          }),
          error => {
            // http failed, let the calling function know that action did not work out
            console.log("Errore");
            console.log(error);
            state.commit("setErrorMessage", error);
            reject(error);
          };
        /*.catch(e => {
          console.log("Eccezione");
          console.log(e);
          //this.errors.push(e)
        });*/
      });
      //commit('setProgressMessage', "Ricerca completata");
      //commit('setDialogMessage', null);
    },

    /* https://vuejsdevelopers.com/2017/08/28/vue-js-ajax-recipes/ */
    /*
    refreshMessage(context) {
        return new Promise((resolve) => {
            this.$http.get('...').then((response) => {
                context.commit('updateMessage', response.data.message);
                resolve();
            });
        });
    }
    */
    //,
    generateLottoInterno(state, prgLotto) {
      var now = new Date();
      var start = new Date(now.getFullYear(), 0, 0);
      var diff = now - start;
      var oneDay = 1000 * 60 * 60 * 24;
      var day = Math.floor(diff / oneDay);
      console.log("Day of year: " + day);
      var s = "000000000" + day;
      s = s.substr(s.length - 3);
      if (!prgLotto) prgLotto = 0;

      prgLotto = "000000000" + prgLotto;
      prgLotto = prgLotto.substr(prgLotto.length - 2);

      state.commit("setLottoInterno", "L" + s + now.getFullYear() + prgLotto);
    }, //generateLottoInterno
    async checkSession({ state, commit, rootState, dispatch }, params) {
      var self = this;

      return new Promise(
        (resolve, reject) => {
          //commit("setProgressMessage", "Ricerca in corso");
          var urlPostRequest = Conf.idempiereUrl + "webservices/rest/v1/auth/checkActiveSession";

          var parameters = {};
          parameters.json = JSON.stringify({ ad_session_uu: state.uuid });

          axios.defaults.headers["Content-Type"] =
            "application/json;charset=utf-8";
          axios
            .post(
              urlPostRequest,
              JSON.stringify(parameters), // the data to post
              {
                headers: {
                  "Content-type": "application/json;charset=utf-8"
                }
              }
            )
            .then(response => {
              //console.log(response.data)
              if (response.data.errorCode == 1) {
                router.push({ path: "/Login/" });
              }
              resolve(response);
            })
            .catch(e => {
              console.log("Eccezione");
              console.log(e);
              //this.errors.push(e)
            });

          //commit("setProgressMessage", "Ricerca completata");
        },
        error => {
          //console.log("**** error")
          console.log("Errore");
          console.log(error);
          state.commit("setErrorMessage", error);
          reject(error);
        }
      );
    },
    callIdempiereRest({
      state,
      commit,
      rootState,
      dispatch,
    }, params) {
      var self = this;
      //dispatch("checkSession", {}, { root: true });
      return new Promise(
        (resolve, reject) => {
          //console.log("1")
          //commit("setProgressMessage", "Ricerca in corso");
          let parameters = params.data
          var urlPostRequest = Conf.idempiereUrl + "webservices/rest/v1/" + params.idempiereRestPath;

          /*console.log("2")
          console.log(urlPostRequest)*/

          parameters.ad_session_uu = state.uuid;

          /*console.log("2.1")
          console.log("parameters")*/

          axios.defaults.headers["Content-Type"] =
            "application/json;charset=utf-8";
          axios
            .post(
              urlPostRequest,
              JSON.stringify(parameters), // the data to post
              {
                headers: {
                  "Content-type": "application/json;charset=utf-8",
                }
              }
            )
            .then(response => {
              //console.log("3")
              //console.log(response)
              // console.log("[m]caricaDatiCPI 123 response")
              // console.log(response.data)
              //var data = response.data.data;
              //console.log(response)
              resolve(response);
            })
            .catch(e => {
              console.log("Eccezione");
              console.log(e);
              console.log(e.response)
              commit("setProgressMessage", null);
              commit("setInfoMessage", null);
              commit("setErrorMessage", e);
              reject(e)
              //this.errors.push(e)
            });

          //commit("setProgressMessage", "Ricerca completata");
        },
        error => {
          //console.log("**** error")
          console.log("Errore");
          console.log(error);
          commit("setProgressMessage", null);
          commit("setInfoMessage", null);
          commit("setErrorMessage", error);
          reject(error);
        }
      );
    },
  },
  getters: {
    currentState: state => state.currentState,
    //stampaLI_DOTes: state => state.stampaLI_DOTes,
    //stampaLI_Saved: state => state.stampaLI_Saved,
    errorMessage: state => state.errorMessage,
    progressMessage: state => state.progressMessage,
    dialogMessage: state => state.dialogMessage,
    infoMessage: state => state.infoMessage,
    progressLoading: state => state.progressLoading,
    breadcrumbItems: state => state.breadcrumbItems,
    lottoInterno: state => state.lottoInterno,
    cliente: state => state.cliente,
    printJob: state => state.printJob,
    queuedSMSMessages: state => state.queuedSMSMessages,
    listaNotifichePush: state => state.listaNotifichePush,
    bozzaSP: state => state.bozzaSP,
    bozzaGVR: state => state.bozzaGVR,
    datiVerbale: state => state.datiVerbale,
    datiRicerca: state => state.datiRicerca,
    bozzaGVRIR: state => state.bozzaGVRIR
  }
});
