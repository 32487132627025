<template>

    <v-content>
        <h1 align="center">Scadenziario Verifiche Ridotto</h1>

        <v-card align="center">
            <v-card-title>
                Processo per la stampa dello scadenziario
            </v-card-title>

            <v-row align="center">
                    <v-col class="ml-4">
                        <v-autocomplete clearable label="Cliente" hide-no-data hide-selected :items="bpartners"
                            item-text="name" item-value="c_bpartner_id" v-model="c_bpartner_id"
                            :search-input.sync="ricercaCliente" dense hide-details outlined
                            @change="getLocations(c_bpartner_id)"></v-autocomplete>
                    </v-col>
                    <v-col class="mr-4">
                        <v-text-field type="number" v-model="mesi" label="Mesi" dense hide-details outlined
                            clearable></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="ml-4">
                        <v-select v-model="selectedgvr_sp_sc" :items="gvr_sp_sc" item-text="name" item-value="value"
                            dense hide-details outlined label="GVR-SP_SC"></v-select>
                    </v-col>
                    <v-col class="mr-4">
                        <v-select v-model="normativa" :items="normative" item-text="name" item-value="value" dense
                            hide-details outlined label="Normativa" clearable></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="ml-4 mr-4">
                        <v-responsive align="center">
                            <v-autocomplete v-model="c_bpartner_location_id" label="Indirizzo" :items="locations"
                            item-text="name" item-value="c_bpartner_location_id" dense hide-details
                                outlined clearable></v-autocomplete>
                        </v-responsive>
                    </v-col>
                </v-row>

                <v-radio-group v-model="tipoScadenziario">
                    <v-row class="ml-2">
                        <v-col>
                            <v-radio value="C" label="Scadenziario Completo"></v-radio>
                        </v-col>
                        <v-col>
                            <v-radio value="R" label="Scadenziario Ridotto"></v-radio>
                        </v-col>
                    </v-row>
                </v-radio-group>

            <v-card-actions>
                <v-responsive align="center" class="ml-n4 mt-2">
                    <v-btn :disabled="checkFields" @click="downloadScadenziarioVerifiche()" color="seritec"
                        class="white--text">Stampa scadenziario</v-btn>
                    <v-btn @click="goToHome()" class="error ml-2" color="error">Torna alla home</v-btn>
                </v-responsive>
            </v-card-actions>
        </v-card>
    </v-content>
</template>
<script>
/* eslint-disable */
import MixingCommonComp from '../mixin/MixingCommonComp'


export default {
    mixins: [MixingCommonComp],
    components: {},

    data() {
        return {
            c_bpartner_id: null,
            mesi: null,
            gvr_sp_sc: null,
            normativa: null,
            c_bpartner_location_id: null,

            bpartners: [],
            ricercaCliente: "",
            isTechnician: false,
            isAdmin: false,
            gvr_sp_sc: [
                { value: 'TUT', name: 'TUTTE' },
                { value: 'GVR', name: 'GVR' },
                { value: 'SP-SC', name: 'SPC' }
            ],
            normative: [
                { value: 'A', name: 'D.L.G.S. 81/08 (All. VII) + D.M. 11/04/11' },
                { value: 'B', name: 'D.P.R. 462/01 (messe a terra)' },
                { value: 'C', name: 'D.P.R. 162/99 (ascensori)' },
                { value: 'D', name: 'D. Lgs. 81/08 Art. 71.8 (verifiche tecniche)' },
                { value: 'E', name: 'D. Lgs. 81/08 All. VI (funi/catene)' }
            ],
            selectedgvr_sp_sc: null,

            tipoScadenziario: "C",
            locations: [],
        }
    },

    methods: {
        async getUserRoles() {
            let ad_user_id = this.$session.get("ad_user_id")
            let parameters = {};
            parameters.data = { ad_user_id: ad_user_id };
            parameters.idempiereRestPath = "vecos/getUserRoles";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.roles = response.data.roles;
            })
        },

        checkUserRole() {
            for (let i in this.roles) {
                if (this.roles[i].name == "Seritec Admin SU") {
                    this.isAdmin = true;
                }

            }
            if (!this.isAdmin) {
                this.isTechnician = true;
            }
        },

        async getLocations(c_bpartner_id) {
            if(c_bpartner_id == null){
                return;
            }
            let parameters = {};
            parameters.data = {c_bpartner_id: c_bpartner_id};
            parameters.idempiereRestPath="effecitech/getBPLocations";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                console.log(response);
                this.locations = response.data.locations;
            });
        },

        goToHome() {
            this.$router.push("/")
        },

        downloadScadenziarioVerifiche() {

            console.log("downloadScadenziarioVerifiche");
            this.$store.commit("setProgressMessage", "Download in corso");
            this.$store.commit("setProgressLoading", true);
            let parameters = {};
            parameters.idempiereRestPath = "print/addPrintJob";
            let data = {};

            data.parameters = "{c_bpartner_id: " + this.c_bpartner_id;
            data.parameters = data.parameters + ", C_BPartner_ID:" + this.c_bpartner_id;
            
            if(this.mesi != null){
                data.parameters = data.parameters + ", mesi :" + this.mesi;
            }
            if(this.selectedgvr_sp_sc != null){
                data.parameters = data.parameters + ", GVR-SP_SC :" + this.selectedgvr_sp_sc;
            }
            if(this.normativa != null){
                data.parameters = data.parameters + ", normativa:" + this.normativa;
            }
            if(this.c_bpartner_location_id != null){
                data.parameters = data.parameters + ", C_BPartner_Location_ID:" + this.c_bpartner_location_id;
            }
            data.parameters = data.parameters + "}";

            if (this.tipoScadenziario == "R") {
                data.nomeStampa = "Stampa scad Verifiche ridotto Seritec";
            }
            else if (this.tipoScadenziario = "C") {
                data.nomeStampa = "Stampa Scad Verifiche ridotto (Admin) Seritec";
            }

            parameters.data = data;
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                console.log(response);
                if (response.data.hasOwnProperty("file")) {
                    const linkSource =
                        "data:application/pdf;base64," + response.data.file;
                    const downloadLink = document.createElement("a");
                    const fileName = "ScadenziarioVerifiche" + ".pdf";

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    this.$store.commit("setProgressMessage", null);
                } else {
                    this.$store.commit("setProgressMessage", null);
                    var errorMsg = "Download non riuscito";
                    if (response.data.hasOwnProperty("message")) {
                        errorMsg = errorMsg + "\n" + response.data.message;
                    }
                    this.$store.commit("setErrorMessage", errorMsg);
                }
            });
        },
    },

    async mounted() {
        await this.getUserRoles();
        this.checkUserRole();

        this.selectedgvr_sp_sc = this.gvr_sp_sc[0].value;

    },

    computed: {
        checkFields() {
            if(!this.c_bpartner_id || this.c_bpartner_id <= 0){
                return true;
            }
            return false;
        },
    },

    watch: {
        ricercaCliente: function () {
            if (
                this.ricercaCliente == null ||
                this.ricercaCliente == "" ||
                this.ricercaCliente.length < 3
            )
                return;
            let parameters = {};
            parameters.data = { name: this.ricercaCliente };
            parameters.idempiereRestPath = "effecitech/cercaCliente";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                if (response.data.result == "OK") {
                    this.bpartners = response.data.listaClienti;
                }
            });
        }
    }
}
</script>