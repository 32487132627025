/* eslint-disable */
import {
  Conf
} from '../conf/defaults'
import axios from 'axios'

// Gestiche i tipi documento e stampanti
// I tipi documento sono definiti staticamente nel codice

//Fare una tabella in ARCAWS dove scrivere
//tipoDoc (campo libero)
//tipoStampa (A per automatica M per manuale)
//dispositivoDiStampa (name della Printer)
/*
CREATE TABLE ARCAWS.dbo.PrintConfig(
  TipoDoc varchar(100) NULL,
  TipoStampa varchar(20) NULL,
  DispositivoDiStampa varchar(255) NULL
) GO
*/

// tabella con i job di stampa **PrintJob**
/*
    Printer printer
    String jasperServerPathReport
    int jasperRECORD_ID;
    String state; //new, senttoclient, expired, queue, done, error
    byte[] data
*/

// una tabella con le stampanti **Printer** (che poi avranno il match con i medoti di stampa automatica)
/*  
    String name;
    Computer computer;
    String description;
    String capabilities;
    String isdefault;
    Date createTimestamp;
    String state;
*/

// una tabella dei dispositivi di stampa **Computer**
/*
    String name;
    String description;
    String inet;
    String hostname;
    String version;
    Date createTimestamp;
    String state;
    String KEY;
*/

export default {
  namespaced: true,
  state: {
  },
  mutations: {},
  actions: {
    caricaPrintConfig({
      state,
      commit,
      rootState
    }, params) {
      var self = this

      console.log("uuid  " + rootState.uuid)
      var datiDaRestituire = []

      return new Promise((resolve, reject) => {
        //commit('setProgressMessage', "Ricerca in corso");

        var urlPostRequest = Conf.arcawsurl + "dbop/operazioneArca"
        // console.log("[m]caricaDatiCPI urlPostRequest >>>" + urlPostRequest);

        var parameters = {}
        parameters.query = state.configStampaQuery
        parameters.fieldTypes = JSON.stringify(state.configStampaFieldTypes)

        parameters.uuid = rootState.uuid
        parameters.operation = "query"

        axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
        axios.post(urlPostRequest,
            //parameters,
            JSON.stringify(parameters), // the data to post
            {
              headers: {
                'Content-type': 'application/json;charset=utf-8',
              }
            }).then(response => {
            var data = response.data.data

            for (var i in data) {
              //alert(i);
              var riga = data[i]

              var linea = {}

              linea.PrintConfig_id = riga.PrintConfig_id.value
              linea.TipoDoc = riga.TipoDoc.value
              linea.TipoStampa = riga.TipoStampa.value
              linea.DispositivoDiStampa = riga.DispositivoDiStampa.value

              // console.log("-i-" + i)
              datiDaRestituire[i] = linea
            }
            console.log(datiDaRestituire)

            resolve(datiDaRestituire);
            //commit('setProgressMessage', "Ricerca completata");
          })
          .catch(e => {
            console.log("Eccezione")
            console.log(e)
            state.commit('setErrorMessage', e);
            //this.errors.push(e)
          })
      }, error => {
        //console.log("**** error")
        console.log("Errore")
        console.log(error)
        state.commit('setErrorMessage', error);
        reject(error);
      })
    },
    // Torna il pdf e l'indicazione se verrà stampato automaticamente o 
    // visualizzato a video
    generaPDF({
      dispatch,
      state,
      commit,
      rootState
    }, params) {
      var self = this;
      console.log("* generaPDF");
      //console.log("* generaPDF 1111");

      return new Promise(
        (resolve, reject) => {

        var urlPostRequest = Conf.ridproxyUrl + "/pcli/getPdf";

        console.log("******** urlPostRequest " + urlPostRequest);
        /*
          getPdf si aspetta come parametri
          listP.add("adempiereServerID");   --> id della tabella adempiere_server
          listP.add("jrparams");            --> parametri da passare al report
          jsonObj.uuid                      --> uuid corrente o un alternativa l'utente
        */

        /*
          All'interno di jrparams.
          REPORT_PATH --> path del report all'interno di jasper server

        */

        //console.log("******** 1");

        var risp = {};
        //params.user = "impasti";
        params.adempiereServerID = "" + Conf.codiceDitta + "";
        params.uuid = rootState.uuid
        console.log("params rootState.uuid" + rootState.uuid);

        axios.defaults.headers["Content-Type"] =
          "application/json;charset=utf-8";
        axios
          .post(
            urlPostRequest,
            JSON.stringify(params), // the data to post
            {
              headers: {
                "Content-type": "application/json;charset=utf-8"
              }
            }
          )
          .then(response => {
            console.log("********* 2");

            
            //console.log(params.TipoDoc);
            //console.log(datiConfigStampanti)

            //CERCO LA CONFIGURAZIONE
            /*
            for (var idx in datiConfigStampanti) {
              var datiConfig = datiConfigStampanti[idx]
              console.log(datiConfig)
              if (params.TipoDoc == datiConfig.TipoDoc) {
                response.TipoDoc = datiConfig.TipoDoc
                response.TipoStampa = datiConfig.TipoStampa
                response.DispositivoDiStampa = datiConfig.DispositivoDiStampa
              }
            }*/


            //params.TipoStampa
            var tipoStampa = "B";


            if (tipoStampa == "A") {

              console.log(response.TipoDoc);

              response.versionerichiesta = "";
              response.reportinfo = {};
              response.reportinfo.TipoDoc = response.TipoDoc;

              response.reportinfo.numcopie = params.qtaEtichette;
              response.reportdata = response.data.file;
              //response.reportdefinition = "1"

              console.log(response);
              //console.log(response.reportdata)

              dispatch("accodaStampa", response).then(response => {
                console.log("* Invia ad accoda stampa");
                resolve(response);
              })

              //commit("accodaStampa", response);
              //rootState.accodaStampa(response)
            }


            console.log(response)

            resolve(response);
          })
          .catch(e => {
            console.log("Eccezione");
            console.log(e);
            //this.errors.push(e)
          });


        },
        error => {
          // http failed, let the calling function know that action did not work out
          console.log("Errore");
          console.log(error);
          state.commit("setErrorMessage", error);
          reject(error);
        }
      );
    },
    accodaStampa({
      dispatch,
      state,
      commit,
      rootState
    }, params) {
      var self = this
      var urlPostRequest = Conf.ridproxyUrl + "/printx/addJob";

      /*
      XPrintJob xPrintJob = new XPrintJob()
      xPrintJob.versionerichiesta = jsonObj.versionerichiesta
      xPrintJob.reportinfo = jsonObj.reportinfo
      xPrintJob.reportdefinition = jsonObj.reportdefinition
      xPrintJob.reportdata = jsonObj.reportdata;
      xPrintJob.status = "DRAFT"
      */

      console.log(params)
      /*
        parameters.versionerichiesta = "1"
        parameters.reportinfo = "1"
        parameters.reportdefinition = "1"
        parameters.reportdata = "1"
      */

      axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
      axios.post(urlPostRequest,
          JSON.stringify(params), // the data to post
          {
            headers: {
              'Content-type': 'application/json;charset=utf-8',
            }
          }).then(response => {
          //console.log(response)
        })
        .catch(e => {
          console.log("Eccezione")
          console.log(e)
          //state.commit('setErrorMessage', e);
          //this.errors.push(e)
        })
    },
    inviaAStampante({
      state,
      commit,
      rootState
    }, params) {
      var self = this

      console.log("uuid  " + rootState.uuid)
      var datiDaRestituire = []

      return new Promise((resolve, reject) => {
        commit('setProgressMessage', "Ricerca in corso");

        var urlPostRequest = Conf.arcawsurl + "printx/operazioneArca"

        var parameters = {}
        parameters.query = state.configStampaQuery
        parameters.fieldTypes = JSON.stringify(state.configStampaFieldTypes)

        parameters.uuid = rootState.uuid
        parameters.operation = "query"

        axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
        axios.post(urlPostRequest,
            //parameters,
            JSON.stringify(parameters), // the data to post
            {
              headers: {
                'Content-type': 'application/json;charset=utf-8',
              }
            }).then(response => {
            var data = response.data.data
            /*
              for (var i in data) {
                //alert(i);
                var riga = data[i]

                var linea = {}

                linea.TipoDoc = riga.TipoDoc.value
                linea.TipoStampa = riga.TipoStampa.value
                linea.DispositivoDiStampa = riga.DispositivoDiStampa.value

                // console.log("-i-" + i)
                datiDaRestituire[i] = linea
              }
            */
            resolve(datiDaRestituire);
            commit('setProgressMessage', "Ricerca completata");
          })
          .catch(e => {
            console.log("Eccezione")
            console.log(e)
            state.commit('setErrorMessage', e);
            //this.errors.push(e)
          })
      }, error => {
        //console.log("**** error")
        console.log("Errore")
        console.log(error)
        state.commit('setErrorMessage', error);
        reject(error);
      })
    },
    listPrintJobs({
      state,
      commit,
      rootState,
      dispatch,
    }, params) {
      var self = this;
      dispatch("checkSession", {}, { root: true });
      return new Promise(
        (resolve, reject) => {
          //commit("setProgressMessage", "Ricerca in corso");
          let parameters = {}
          var urlPostRequest = Conf.ridproxyUrl + "callIdempiereRest"; 

          parameters.json = {ad_session_id:params.ad_session_id}

          parameters.uuid = rootState.uuid;
          parameters.idempiereRestPath = "print/listPrintJobs"

          axios.defaults.headers["Content-Type"] =
            "application/json;charset=utf-8";
          axios
            .post(
              urlPostRequest,
              JSON.stringify(parameters), // the data to post
              {
                headers: {
                  "Content-type": "application/json;charset=utf-8"
                }
              }
            )
            .then(response => {
              // console.log("[m]caricaDatiCPI 123 response")
              // console.log(response.data)
              //var data = response.data.data;
              //console.log(response)
              resolve(response);
            })
            .catch(e => {
              console.log("Eccezione");
              console.log(e);
              //this.errors.push(e)
            });

          //commit("setProgressMessage", "Ricerca completata");
        },
        error => {
          //console.log("**** error")
          console.log("Errore");
          console.log(error);
          state.commit("setErrorMessage", error);
          reject(error);
        }
      );
    },
  },
  getters: {}
}
