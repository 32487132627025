<template>
    <v-menu
      v-if="type == 'menu'"
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          :label="label"
          :prepend-icon="prependIcon"
          :outlined="outlined"
          readonly
          v-bind="attrs"
          v-on="on"
          :dense="dense"
          :hide-details="`hide-details`"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menu"
        v-model="time"
        full-width
        :format="format"
        @click:minute="$refs.menu.save(time)"
        @input="returnTime"
        :allowed-hours="allowedHours"
        :allowed-minutes="allowedMinutes"
      ></v-time-picker>
    </v-menu>
    <v-dialog
      v-else
      ref="dialog"
      v-model="dialog"
      :return-value.sync="time"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          :label="label"
          :prepend-icon="prependIcon"
          :outlined="outlined"
          readonly
          v-bind="attrs"
          v-on="on"
          :dense="dense"
          :hide-details="`hide-details`"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="dialog"
        v-model="time"
        full-width
        :format="format"
        @click:minute="$refs.menu.save(time)"
        @input="returnTime"
        :allowed-hours="allowedHours"
        :allowed-minutes="allowedMinutes"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(time)"> OK </v-btn>
      </v-time-picker>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      value: { type: String, default: "00:00" },
      label: { type: String, default: "" },
      outlined: { type: Boolean, default: false },
      format: { type: String, default: "ampm" },
      prependIcon: { type: String, default: "" },
      prependInnerIcon: { type: String, default: "" },
      allowedHours: { type: Function, default: (h) => h >= 0 },
      allowedMinutes: { type: Function, default: (m) => m >= 0 },
      allowedStep: { type: Function },
      type: { type: String, default: "menu" },
      dense: { type: Boolean, default: false },
      'hide-details': { type: Boolean, default: false },
    },
    data() {
      return {
        menu: false,
        dialog: true,
        time: "00:00",
      };
    },
    methods: {
      returnTime() {
        this.$emit("input", this.time);
      },
    },
    mounted(){
      this.time = this.value;
    },
    watch: {
      value: function () {
        this.time = this.value;
      },
    },
  };
  </script>
  
  <style>
  </style>