<template>
  <v-autocomplete
    v-model="record_id"
    :items="options"
    item-value="record_id"
    :item-text="(item) => item.trl[language]"
    :label="label"
    :outlined="outlined"
    :dense="dense"
    :clearable="clearable"
    :rules="rules"
    :readonly="readonly"
    :disabled="disabled"
    hide-details
    @change="$emit('change', record_id)"
  ></v-autocomplete>
</template>

<script>
/*eslint-disable */
export default {
  name: "Selection",
  props: {
    value: { type: Number },
    column: { type: Object },
    label: { type: String, default: "" },
    outlined: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    language: { type: String, default: "it_IT" },
  },
  emits: ['change'],
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    getOptions() {
      let parameters = {};
      parameters.data = { ad_column_id: this.column.AD_Column_ID };
      parameters.idempiereRestPath = "window/getOptions";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.options = response.data.options;
        if (
          this.options.length == 1 &&
          this.column.IsMandatory &&
          !this.record_id
        ) {
          this.record_id = this.options[0].record_id;
        }
      });
    },
  },
  computed: {
    record_id: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  watch: {
    value: function () {
      this.getOptions();
    },
  },
};
</script>

<style>
</style>