<template>
  <div class="hello">
      <v-container fluid fill-height>
        <v-flex xs12>
          <v-layout column align-center>
            <v-img :src="image" width="400"></v-img>
          </v-layout>
          <br>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
              <h1>{{ msg }}</h1>
              <h2>Utente e Password</h2>

              <v-form ref="form" lazy-validation>
                <v-text-field label="Utente" v-model="utente" required id="username"></v-text-field>

                <v-text-field
                  v-on:keyup="pwdup"
                  label="Password"
                  v-model="password"
                  :type="'password'"
                  required
                  id="password"
                ></v-text-field>

                <v-btn @click="submit" id="submit">Conferma</v-btn>

                <v-btn @click="clear" id="clear">Annulla</v-btn>
              </v-form>
              <v-alert v-if="errors!=null" type="error" :value="true">{{errors}}</v-alert>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-container>
  </div>
</template>

<script src="https://unpkg.com/vue/dist/vue.js"></script>
<script src="https://unpkg.com/vuetify/dist/vuetify.js"></script>

<script>
/* eslint-disable */

import axios from "axios";
import mixinCommon from "@/mixin/MixingCommonComp.js";

export default {
  mixins: [mixinCommon],
  dialog: false,
  sound: true,
  widgets: false,
  name: "Login",
  data() {
    return {
      operationRunningTitle: "INFO",
      errorDialogTitle: "Error",
      valid: true,
      loading: true,
      msg: "Login",
      utente: "",
      password: "",
      errors: null,
      image: require("@/assets/logoSeritec.png")
    };
  },
  methods: {
    pwdup: function(e) {
      if (e.keyCode === 13) {
        //alert('Enter was pressed');
        this.submit();
      } //else if (e.keyCode === 50) {
      //alert('@ was pressed');
      //}
      //this.log += e.key;
    },
    submit() {
      console.log("submit");
      var parameters = {};
      parameters.utente = this.utente;
      parameters.password = this.password;

      this.$store.commit("setProgressMessage", "Accesso in corso");

      //this.$store.commit("setUUID", this.$session.get("uuid"))
      this.$store.dispatch("login/richiestaLogin", parameters).then(
        response => {
          console.log(response)
          if (response.result == "KO" || response.result=="Wrong username") {
            this.errors = response.result == "KO"?"Password Sbagliata":"Utente non trovato";
            this.$store.commit("setProgressMessage", null);
          } else {
            this.$session.start();
            //this.$session.set("ad_client_id",response.data.ad_client_id)
            this.$session.set("uuid", response.session_uu);
            //this.$store.commit("setNavBarPermanent", true);
            this.$store.commit("setUUID", response.session_uu);
            this.$session.set("ad_client_id", response.ad_client_id);
            this.$session.set("ad_user_id", response.ad_user_id);
            this.$session.set("ad_org_id", response.ad_org_id);
            this.$session.set("ad_user_id", response.ad_user_id);
            this.$session.set("name", response.name);
            //this.$store.commit("setNavBarPermanent", true);
            this.$router.push({ path: "/" });
            this.$store.commit("setProgressMessage", null);
            this.$store.commit("setDatiRicerca",null)
          }
        },
        error => {
          this.$store.commit("setErrorMessage", "Errore di login");
          //console.error("Got nothing from server. Prompt user to check internet connection and try again")
        }
      );
    },
    clear() {
      console.log("clear");
      this.utente = "";
      this.password = "";
    }
  },
  // Fetches posts when the component is created.
  created() {
    this.$store.commit("setProgressMessage", null);
    //console.log(this.$ridproxy);
  },
  computed: {
    progressMessage: function() {
      return this.$store.state.progressMessage;
    },
    progressMessageEnable: function() {
      if (this.$store.state.progressMessage == null) {
        return false;
      } else {
        return true;
      }
    },
    errorMessageEnable: function() {
      if (this.$store.state.errorMessage == null) {
        //console.log("false")
        return false;
      } else {
        //console.log("true")
        return true;
      }
    },
    errorMessage: function() {
      return this.$store.state.errorMessage;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.loading-dialog {
  background-color: #303030;
}
</style>
