<template>
  <v-app>
    <v-app-bar app color="seritec" dark>
      <v-toolbar-title>
        <b>BENVENUTO {{ this.$session.get("name") }}</b>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="home" v-if="loginEffettuato()">Home</v-btn>
      <!--  RIMOSSO (Per francesca bastano home e logout)
      
      <v-btn text @click="recenti" v-if="loginEffettuato()"
        >Verbali recenti</v-btn
      >
      <v-btn text @click="esportazioneVerbali" v-if="loginEffettuato()"
        >Esportazione Verbali</v-btn
      >
      <v-btn text @click="modificaFirma" v-if="loginEffettuato()"
        >Modifica firma</v-btn
      >
           
      -->
      <v-btn text @click="logout" v-if="loginEffettuato()">
        Logout
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-content>
      <v-container fluid>
        <router-view></router-view>
        <DialogOperationRunning
          name="dialogOperationRunning"
          v-model="progressMessageEnable"
          :dialogTitle="operationRunningTitle"
          :dialogMessage="progressMessage"
          :loading="progressLoading"
        />
        <DialogError
          v-model="errorMessageEnable"
          name="dialogError"
          :dialogTitle="errorDialogTitle"
          :dialogMessage="errorMessage"
        />
        <DialogInfo
          v-model="infoMessageEnable"
          name="dialogError"
          dialogTitle="Info"
          :dialogMessage="infoMessage"
        />
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import MixinCommonComp from "./mixin/MixingCommonComp";

export default {
  mixins: [MixinCommonComp],
  name: "App",
  data: () => ({
    //
  }),
  methods: {
    logout() {
      this.$session.clear();
      this.$session.destroy();
      this.$router.push({ path: "/Login/" });
    },
    modificaFirma() {
      this.$router.push({ path: "/firma" });
    },
    home() {
      this.$router.push({ path: "/" });
    },
    recenti() {
      this.$router.push({ path: "/recenti" });
    },
    esportazioneVerbali() {
      this.$router.push({ path: "/esportazioneVerbali" });
    },
  },
};
</script>
