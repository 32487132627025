<template>
  <span>
  <v-select
    v-model="record_id"
    :items="options"
    item-value="value"
    :item-text="(item) => item.trl[language]"
    :label="label"
    :outlined="outlined"
    :dense="dense"
    :clearable="clearable"
    :rules="rules"
    :readonly="readonly"
    :disabled="disabled"
    hide-details
    @change="$emit('change', record_id)"
  ></v-select>
</span>
</template>

<script>
/*eslint-disable */
export default {
  name: "SelectionList",
  props: {
    value: { type: String },
    column: { type: Object },
    label: { type: String, default: "" },
    outlined: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    language: { type: String, default: "it_IT" },
  },
  emits: ['change'],
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getListOptions();
  },
  methods: {
    getListOptions() {
      let parameters = {};
      parameters.data = { ad_column_id: this.column.AD_Column_ID };
      parameters.idempiereRestPath = "window/getListOptions";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.options = response.data.options;
      });
    },
  },
  computed: {
    record_id: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  watch: {
    column: function () {
      this.getListOptions();
    },
  },
};
</script>

<style>
</style>