<template>
  <v-flex d-flex>
    <v-spacer></v-spacer>
    <v-btn v-if="order" @click="downloadFile"
      ><v-icon>mdi-download</v-icon>Download Ordine</v-btn
    >
    <v-spacer></v-spacer>
  </v-flex>
</template>

<script>
/* eslint-disable */
import mixin from "@/mixin/MixingCommonComp.js";

export default {
  mixins: [mixin],
  data() {
    return {
      order: null,
    };
  },
  methods: {
    downloadOrder(c_order_id) {
      this.showLoadingDialog(true, "Download in corso...");
      let parameters = {};
      parameters.data = { c_order_id: c_order_id };
      parameters.idempiereRestPath = "seritec/downloadOrder";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.showLoadingDialog(false);
          this.order = response.data;
          this.downloadFile();
        } else {
          this.showErroDialog(response.data.message);
        }
      });
    },
    downloadFile() {
      const linkSource = "data:application/pdf;base64," + this.order.data;
      const downloadLink = document.createElement("a");
      const fileName = this.order.documentno + ".pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
  },
  mounted() {
    let c_order_id = this.$route.query.c_order_id;

    if (!c_order_id) {
      this.$router.push({ path: "/" });
    }

    this.downloadOrder(c_order_id);
  },
};
</script>

<style>
</style>