/* eslint-disable */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import VueSession from 'vue-session'
import {
  store
} from './store/store'
import {
  Conf
} from './conf/defaults'
import 'babel-polyfill'
import VueSignaturePad from 'vue-signature-pad';
//const fb = require('./firebaseConfig.js')

//  https://jsfiddle.net/x0qufw78/

export const serverBus = new Vue()


Vue.use(vuetify)

//Vue.use(vuetify);

Vue.use(VueSignaturePad)

var options = {
  persist: true
}
Vue.use(VueSession, options)

Vue.config.productionTip = false
Vue.prototype.$windowTitle = Conf.windowTitle
//Vue.prototype.$fb = fb;

/* eslint-disable no-new */
new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')   