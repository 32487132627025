<template>
    <v-container fluid>
        <v-tabs v-model="tab">
            <v-tab @click="resetFields()">Tipo di servizio</v-tab>
            <v-tab disabled>Selezione attività</v-tab>
            <v-tab disabled>Modifica o inserimento attività</v-tab>

            <v-tab-item> <!-- TIPO DI SERVIZIO -->
                <v-col>
                    <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" light
                        @click="goToTragitto()">TRAGITTO GIORNALIERO</v-btn>
                </v-col>
                <v-col>
                    <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" light
                        @click="goToCarburante()">SCHEDA CARBURANTE</v-btn>
                </v-col>
                <v-col>
                    <v-btn color="seritec" class="white--text" height="150px" width="100%" size="x-large" light
                        @click="goToRendicontazionePropria()">RENDICONTAZIONE <br> VEICOLO PROPRIO</v-btn>
                </v-col>
            </v-tab-item>

            <v-tab-item> <!-- SELEZIONE RENDICONTAZIONE-->
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <dataPicker v-model="dateFrom" label="Inizio ricerca" outlined dense></dataPicker>
                        </v-col>
                        <v-col>
                            <dataPicker v-model="dateTo" label="Fine ricerca" outlined dense></dataPicker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-autocomplete :disabled="setTechnician" v-model="ad_user_id" outlined dense
                                :items="technicians" item-value="ad_user_id" item-text="name" clearable
                                label="Utilizzatore">
                            </v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-autocomplete :disabled="this.rendicontazione" v-model="a_asset_id" :items=vehicles
                                item-value="a_asset_id" item-text="vehicle" outlined dense clearable label="Veicolo">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-flex d-flex>
                        <v-spacer></v-spacer>
                        <v-btn class="white--text" color="seritec" @click="getFilteredResults()">Filtra ricerca</v-btn>
                    </v-flex>
                </v-container>

                <!-- SCHEDA CARBURANTE -->
                <div v-if="carburante">
                    <h2 class="text-center mt-4"> Ultime rendicontazioni</h2>
                    <b class="text-center">Seleziona una rendicontazione esistente o clicca sul bottone "Crea nuova
                        rendicontazione" per passare alla finestra di modifica</b>
                    <v-data-table :items="rendicontazioni" :headers="headers" @click:row="goToDetails">
                        <template v-slot:item.dataRifornimento="{ item }">
                            {{ dateToItalianFormat(item.dataRifornimento) }}
                        </template>
                    </v-data-table>

                    <v-btn align="center" color="seritec" class="white--text" justify-center @click="makeNew()">
                        Crea nuova scheda carburante
                    </v-btn>

                    <v-btn class="ml-2" color="success" @click="openDialogRendicontazione()">Rendicontazione
                        mensile</v-btn>
                </div>

                <!-- TRAGITTO GIORNALIERO -->
                <div v-if="tragitto">
                    <h2 class="text-center mt-4"> Ultimi tragitti</h2>
                    <b class="text-center">Seleziona un tragitto esistente o clicca sul bottone "Crea nuovo tragitto"
                        per passare alla finestra di modifica</b>


                    <v-data-table :items="tragitti" :headers="headersTragitti" @click:row="goToDetails">

                        <template v-slot:item.dataTragitto="{ item }">
                            {{ dateToItalianFormat(item.dataTragitto) }}
                        </template>
                    </v-data-table>

                    <v-btn align="center" color="seritec" class="white--text" justify-center @click="makeNew()">
                        Crea nuovo tragitto giornaliero
                    </v-btn>

                    <v-btn class="ml-2" color="success" @click="openDialogRendicontazione()">Rendicontazione
                        tragitti</v-btn>
                </div>
                <!-- RENDICONTAZIONE VEICOLO PROPRIO -->
                <div v-if="rendicontazione">
                    <h2 class="text-center mt-4">Rendicontazione veicolo proprio</h2>
                    <b class="text center">Seleziona una rendicontazione propria esistente o clicca sul bottone
                        "Crea nuova rendicontazione propria" per passare alla finestra di modifica
                    </b>

                    <v-data-table :items="rendicontazioniProprie" :headers="headersPropri" @click:row="goToDetails">

                        <template v-slot:item.dataTragitto="{ item }">
                            {{ dateToItalianFormat(item.dataTragitto) }}
                        </template>

                    </v-data-table>

                    <v-btn color="seritec" class="white--text" @click="makeNew()">
                        Crea nuova rendicontazione propria
                    </v-btn>

                    <v-btn class="ml-2" color="success" @click="openDialogRendicontazione()">Rendicontazione propria
                        mensile</v-btn>

                </div>

                <v-btn class="mt-4 mb-4" color="error" @click="resetFields">Indietro</v-btn>

            </v-tab-item>

            <v-tab-item> <!-- MODIFICA O INSERIMENTO RENDICONTAZIONE-->
                <div v-if="carburante">
                    <v-card align="center">
                        <Window v-model="record_id" name="Scheda carburante" ref="windowRendicontazione"
                            v-on:save="onSave" v-on:change="onChangeRendicontazione"
                            v-on:delete="onDeleteRendicontazione" v-on:datafetched="onDataFetchedRendicontazione">
                        </Window>
                        <v-row class="ml-2 mt-2">
                            <AttachmentManager v-model="record_id" table-name="FCT_RendicontazioneKm" text
                                accept="capture=camera,image/*">
                            </AttachmentManager>
                        </v-row>

                        <v-btn class="mb-4 white--text" color="error" @click="goBack()">Torna indietro</v-btn>
                    </v-card>
                </div>

                <div v-if="tragitto">
                    <v-card align="center">
                        <Window v-model="record_id" name="Tragitto Giornaliero" ref="windowTragitto" v-on:save="onSave"
                            v-on:change="onChangeTragitto" v-on:delete="onDeleteTragitto">
                        </Window>
                        <v-row class="ml-2 mt-2">
                            <AttachmentManager v-model="record_id" table-name="FCT_TragittoGiornaliero" text
                                accept="capture=camera,image/*">
                            </AttachmentManager>
                        </v-row>

                        <v-btn class="mb-4" color="error" @click="goBack()">Torna indietro</v-btn>
                    </v-card>
                </div>

                <div v-if="rendicontazione">
                    <v-card align="center">
                        <Window v-model="record_id" name="Rendicontazione veicolo proprio"
                            ref="windowRendicontazionePropria" v-on:save="onSave"
                            v-on:change="onChangeRendicontazionePropria" v-on:delete="onDeleteRendicontazionePropria">
                        </Window>

                        <v-row class="ml-2 mt-2">
                            <AttachmentManager v-model="record_id" table-name="FCT_RendicontazionePropria" text
                                accept="capture=camera,image/*">
                            </AttachmentManager>
                        </v-row>

                        <v-btn class="mb-4" color="error" @click="goBack()">Torna indietro</v-btn>
                    </v-card>
                </div>
            </v-tab-item>


        </v-tabs>

        <v-dialog v-model="dialogRendicontazione" persistent>
            <v-card>
                <v-card-title>
                    <span v-if="tragitto">Rendicontazione tragitti</span>
                    <span v-else-if="carburante">Rendicontazione carburante</span>
                    <span v-else-if="rendicontazione">Rendicontazione</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <dataPicker v-model="dateFromRendicontazione" label="Data inizio" outlined dense></dataPicker>
                        </v-col>
                        <v-col>
                            <dataPicker v-model="dateToRendicontazione" label="Data fine" outlined dense></dataPicker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-autocomplete :disabled="setTechnician" v-model="rendicontazione_ad_user_id" outlined dense
                                :items="technicians" item-value="ad_user_id" item-text="name" clearable
                                label="Utilizzatore">
                            </v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-autocomplete :disabled="this.rendicontazione" v-model="rendicontazione_a_asset_id"
                                :items=vehicles item-value="a_asset_id" item-text="vehicle" outlined dense clearable
                                label="Veicolo">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <!-- RENDICONTAZIONI TRAGITTI -->
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="closeDialogRendicontazione()"> Chiudi finestra</v-btn>
                    <v-btn v-if="tragitto" :disabled="!this.dateFromRendicontazione
                        || !this.dateFromRendicontazione
                        || this.rendicontazione_a_asset_id == null" color="seritec"
                        @click="downloadRendicontazione()" class="white--text">
                        Scarica rendicontazione
                    </v-btn>

                    <v-btn v-if="carburante" :disabled="!this.dateFromRendicontazione
                        || !this.dateFromRendicontazione
                        || this.rendicontazione_a_asset_id == null" color="seritec"
                        @click="downloadRendicontazione()" class="white--text">
                        Scarica rendicontazione
                    </v-btn>

                    <v-btn v-if="rendicontazione" :disabled="!this.dateFromRendicontazione
                        || !this.dateFromRendicontazione" color="seritec"
                        @click="downloadRendicontazione()" class="white--text">
                        Scarica rendicontazione
                    </v-btn>
                </v-card-actions>

            </v-card>



        </v-dialog>
    </v-container>
</template>

<script>
/*eslint-disable */
import MixingCommonComp from '../mixin/MixingCommonComp';
import Window from '../components/Window.vue';
import DialogInfo from '../components/DialogInfo.vue';
import AttachmentManager from '../components/AttachmentManager.vue';
import DataPicker from '../components/DataPicker.vue';

export default {
    mixins: [MixingCommonComp],
    components: { Window, DialogInfo, AttachmentManager, DataPicker },

    data() {
        return {
            tab: 0,
            record_id: null,
            rendicontazioni: [],
            tragitti: [],
            rendicontazioniProprie: [],
            photo: [],
            technicians: [],
            vehicles: [],
            ad_user_id: null,
            selectedRow: {},

            headers: [
                { text: "Veicolo", value: "veicolo" },
                { text: "Targa", value: "targa" },
                { text: "Data rifornimento", value: "dataRifornimento" },
                { text: "Utilizzatore", value: "utilizzatore" },
                { text: "Km precedenti", value: "kmPrecedenti" },
                { text: "Km attuali", value: "kmAttuali" },
                { text: "Importo", value: "importo" }
            ],

            headersTragitti: [
                { text: "Veicolo", value: "veicolo" },
                { text: "Targa", value: "targa" },
                { text: "Utilizzatore", value: "utilizzatore" },
                { text: "Data tragitto", value: "dataTragitto" },
                { text: "Tragitto", value: "tragitto" },
            ],

            headersPropri: [
                { text: "Utilizzatore", value: "utilizzatore" },
                { text: "Data tragitto", value: "dataTragitto" },
                { text: "Tragitto", value: "tragitto" },
                { text: "Km totali", value: "kmTotali"},
                { text: "Importo", value: "importo" },
            ],

            tragitto: false,
            carburante: false,
            rendicontazione: false,

            dateTo: new Date().toISOString().substring(0, 10),
            dateFrom: new Date(),
            a_asset_id: null,
            roles: [],
            capiCommessa: [],
            dialogRendicontazione: false,
            dateFromRendicontazione: null,
            dateToRendicontazione: null,
            rendicontazione_ad_user_id: null,
            rendicontazione_a_asset_id: null,
        }
    },
    methods: {
        async getRendicontazioni() {
            let parameters = {};
            parameters.data = {
                dateTo: this.dateTo, dateFrom: this.dateFrom,
                a_asset_id: this.a_asset_id, ad_user_id: this.ad_user_id
            };
            parameters.idempiereRestPath = "effecitech/getRendicontazioni";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.rendicontazioni = response.data.rendicontazioni;
                console.log(this.rendicontazioni)
            })
        },
        async getTragitti() {
            let parameters = {};
            parameters.data = {
                dateTo: this.dateTo, dateFrom: this.dateFrom,
                a_asset_id: this.a_asset_id, ad_user_id: this.ad_user_id
            };
            parameters.idempiereRestPath = "effecitech/getTragitti";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.tragitti = response.data.tragitti;
                console.log(this.tragitti);
            })
        },

        async getRendicontazioniProprie() {
            let parameters = {};
            parameters.data = {
                dateTo: this.dateTo, dateFrom: this.dateFrom,
                a_asset_id: this.a_asset_id, ad_user_id: this.ad_user_id
            };
            parameters.idempiereRestPath = "effecitech/getRendicontazioniProprie";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.rendicontazioniProprie = response.data.rendicontazioniProprie;
                console.log(this.rendicontazioniProprie);
            })
        },

        goToTragitto() {
            this.tragitto = true;
            this.tab = 1;
        },

        goToCarburante() {
            this.carburante = true;
            this.tab = 1;
        },

        goToRendicontazionePropria() {
            this.rendicontazione = true;
            this.tab = 1;
        },

        goToDetails(e, { item }) {
            this.tab = 2;
            this.selectedRow = item;
            this.record_id = item.record_id;
        },

        makeNew() {
            this.tab = 2;
            this.record_id = 0;
        },

        resetFields() {
            this.tab = 0;
            this.record_id = 0;
            this.tragitto = false;
            this.carburante = false;
            this.rendicontazione = false;
            this.a_asset_id = null;
            if (this.selectedRow) {
                this.selectedRow = {}
            }
        },

        goBack() {
            this.tab = 1;
        },

        onSave(success) {
            if (success) {
                if (this.carburante == true) {
                    this.showInfoDialog("Scheda carburante inserita correttamente");
                    this.getRendicontazioni();
                }
                else if (this.tragitto == true) {
                    this.showInfoDialog("Tragitto giornaliero inserito correttamente");
                    this.getTragitti();
                }
                else if (this.rendicontazione == true) {
                    this.showInfoDialog("Rendicontazione propria inserita correttamente");
                    this.getRendicontazioniProprie();
                }
            }
        },

        getFilteredResults() {
            if (this.carburante == true) {
                this.getRendicontazioni();
            }
            else if (this.tragitto == true) {
                this.getTragitti();
            }
            else if (this.rendicontazione == true) {
                this.getRendicontazioniProprie();
            }
        },

        setDateFrom() {
            var date = new Date();
            this.dateFrom = this.dateFrom.setDate(date.getDate() - 30);
        },

        async getUserRoles() {
            let ad_user_id = this.$session.get("ad_user_id")
            let parameters = {};
            parameters.data = { ad_user_id: ad_user_id };
            parameters.idempiereRestPath = "vecos/getUserRoles";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.roles = response.data.roles;
            })
        },

        onChangeRendicontazione(item) {
            if (item.field.Column.ColumnName == 'A_Asset_ID') {
                this.getLastKmOfVehicle(item.value)
            } else if (item.field.Column.ColumnName == 'AD_User_ID') {
                this.$refs.windowRendicontazione.setValue("A_Asset_ID", null);
                this.$refs.windowRendicontazione.setValue("KmPrecedenti", null);
                this.$refs.windowRendicontazione.setValue("KmAttuali", null);
            }
        },

        onChangeRendicontazionePropria(item) {
            if (item.field.Column.ColumnName == 'AD_User_ID') {
                this.$refs.windowRendicontazionePropria.setValue("Importo", null);
            }
        },

        onDeleteRendicontazione(success) {
            if (success) {
                this.goBack();
                this.getRendicontazioni();
            }
        },

        onDeleteTragitto(success) {
            if (success) {
                this.goBack();
                this.getTragitti();
            }
        },

        onDeleteRendicontazionePropria(success) {
            if (success) {
                this.goBack();
                this.getRendicontazioniProprie();
            }
        },

        async getLastKmOfVehicle(a_asset_id) {
            let parameters = {};
            parameters.data = { a_asset_id: a_asset_id };
            parameters.idempiereRestPath = "effecitech/getLastKmOfVehicle";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                if (response.data.kmAttuali) {
                    let kmPrecedenti = response.data.kmAttuali;
                    this.$refs.windowRendicontazione.setValue("KmPrecedenti", kmPrecedenti);
                }
            })
        },

        getVehicles() {
            let parameters = {};
            parameters.data = {};
            parameters.idempiereRestPath = "effecitech/getVehicles";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.vehicles = response.data.veicoli;
            })
        },

        async getTechnicians() {
            let parameters = {};
            parameters.data = {};
            parameters.idempiereRestPath = "vecos/getTechnicians";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {

                this.technicians = response.data.technicians;
                for (let i in this.technicians) {
                    this.capiCommessa.push(this.technicians[i]);
                }
                this.setTechnician;
            })
        },

        onDataFetchedRendicontazione(record) {
            if (!record.FCT_RendicontazioneKm_ID || record.FCT_RendicontazioneKm_ID <= 0) {
                console.log("onDataFetched", record);
                this.getLastKmOfVehicle(record.A_Asset_ID)
            }
        },

        onChangeTragitto(item) {
            if (item.field.Column.ColumnName == 'AD_User_ID') {
                this.$refs.windowTragitto.setValue("A_Asset_ID", null);
            }
        },

        async openDialogRendicontazione() {
            await this.getTechnicians();
            this.dialogRendicontazione = true;
        },

        closeDialogRendicontazione() {
            this.dialogRendicontazione = false;
            this.dateFromRendicontazione = null;
            this.dateToRendicontazione = null;
            this.rendicontazione_a_asset_id = null;
            this.rendicontazione_ad_user_id = null;
        },

        downloadRendicontazione() {
            console.log("downloadRendicontazione");
            this.$store.commit("setProgressMessage", "Download in corso");
            this.$store.commit("setProgressLoading", true);
            let parameters = {};
            parameters.idempiereRestPath = "print/addPrintJob";
            let data = {};
            if (this.tragitto || this.carburante) {
                data.parameters = "{AD_USER_ID: " + (this.rendicontazione_ad_user_id || -1) + "," +
                    " A_ASSET_ID :" + this.rendicontazione_a_asset_id + "," +
                    " DATE_FROM :" + this.dateFromRendicontazione + "," +
                    " DATE_TO :" + this.dateToRendicontazione + "}";
            } else {
                data.parameters = "{AD_USER_ID:" + (this.rendicontazione_ad_user_id || -1) + "," +
                    " DATE_FROM:" + this.dateFromRendicontazione + "," +
                    " DATE_TO:" + this.dateToRendicontazione + "}";
            }

            if (this.tragitto) {
                data.nomeStampa = "Rendicontazione Tragitti";
            }
            else if (this.carburante) {
                data.nomeStampa = "Rendicontazione Scheda Carburante";
            }
            else if (this.rendicontazione) {
                data.nomeStampa = "Rendicontazione propria";
            }

            parameters.data = data;
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                console.log(response);
                if (response.data.hasOwnProperty("file")) {
                    const linkSource =
                        "data:application/pdf;base64," + response.data.file;
                    const downloadLink = document.createElement("a");
                    const fileName = "Rendicontazione" + ".pdf";

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    this.$store.commit("setProgressMessage", null);
                } else {
                    this.$store.commit("setProgressMessage", null);
                    var errorMsg = "Download non riuscito";
                    if (response.data.hasOwnProperty("message")) {
                        errorMsg = errorMsg + "\n" + response.data.message;
                    }
                    this.$store.commit("setErrorMessage", errorMsg);
                }
            });
        },
    },

    computed: {
        setTechnician() {
            if (this.technicians.length == 1) {
                this.ad_user_id = this.technicians[0].ad_user_id;
                this.rendicontazione_ad_user_id = this.technicians[0].ad_user_id;
                return true;
            }
            return false;
        },
    },

    async mounted() {
        this.showLoadingDialog(true, "Caricamento in corso...")
        await this.getTechnicians();
        this.getVehicles();
        this.getUserRoles();
        this.setDateFrom();
        await this.getRendicontazioni();
        await this.getTragitti();
        await this.getRendicontazioniProprie();
        this.showLoadingDialog(false);
    }
}
</script>