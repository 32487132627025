<template>
    <v-container fluid>
        <v-tabs v-model="tab">
            <v-tab @click="goBack()">Ricerca ordini di vendita</v-tab>
            <v-tab disabled>Righe dell'ordine di vendita</v-tab>
            <!-- MASCHERA DI RICERCA ORDINI DI VENDITA -->
            <v-tab-item>
                <h1 align="center">Pianificazione attività</h1>

                <v-row class="mt-4 mx-4">
                    <v-col>
                        <v-text-field clearable dense label="Ordine di vendita" v-model="documentNo" hide-details
                            outlined></v-text-field>
                        <!-- <v-autocomplete dense :items="odvs" item-value="documentNo" item-text="documentNo"
                        label="Ordine di vendita" v-model="documentNo"></v-autocomplete>  -->
                    </v-col>
                    <v-col>
                        <v-autocomplete clearable label="Cliente" hide-no-data hide-selected :items="bpartners"
                            item-text="name" item-value="c_bpartner_id" v-model="c_bpartner_id"
                            :search-input.sync="ricercaCliente" dense hide-details outlined></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="mx-4">
                    <v-col>
                        <DataPicker clearable dense label="Data Ordine da" v-model="dataOrdineFrom" hide-details outlined>
                        </DataPicker>
                    </v-col>
                    <v-col>
                        <DataPicker clearable dense label="Data ordine a" v-model="dataOrdineTo" hide-details outlined>
                        </DataPicker>
                    </v-col>
                    <!--
                        <v-col>
                        <v-autocomplete clearable :items="status" multiple chips dense label="Stato Avanzamento"
                            v-model="stato" item-text="stato" item_value="value" hide-details outlined></v-autocomplete>
                    </v-col>
                    -->
                </v-row>
                <v-row class="mx-4">
                    <v-col>
                        <v-autocomplete clearable :items="status" multiple chips dense label="Stato Avanzamento"
                            v-model="stato" item-text="stato" item_value="value" hide-details outlined></v-autocomplete>
                    </v-col>
                    <v-col align="center">
                        <v-autocomplete clearable dense label="Tecnico" :items="technicians" item-value="ad_user_id"
                            item-text="name" :disabled="setTechnician" v-model="ad_user_id" hide-details
                            outlined></v-autocomplete>
                    </v-col>

                </v-row>
                <br />
                <br />
                <v-flex d-flex>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-n8 mr-5 white--text" color="seritec"  @click="getOrders()">RICERCA</v-btn>
                </v-flex>
                <br />
                <b>Legenda:</b>
                <v-chip color="brown lighten-2" style="margin: 0px 5px 0px 5px;">Preventivo</v-chip>
                <v-chip color="red" style="margin: 0px 5px 0px 5px;">Attività da pianificare</v-chip>
                <v-chip color="yellow" style="margin: 0px 5px 0px 5px;">Attività in corso</v-chip>
                <v-chip color="blue lighten-2" style="margin: 0px 5px 0px 5px;">Attività da fatturare</v-chip>
                <v-chip color="green" style="margin: 0px 5px 0px 5px;">Attività fatturata</v-chip>
                <br />
                <v-data-table class="mt-3" :items="orders" :headers="headersOdvs" dense
                    :footer-props="{ 'items-per-page-options': [50, 100, 200, -1] }">
                    <template v-slot:[`item.dataPromessa`]="{ item }">
                        {{ dateToItalianFormat(item.dataPromessa) }}
                    </template>
                    <template v-slot:[`item.dataOrdine`]="{ item }">
                        {{ dateToItalianFormat(item.dataOrdine) }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip :color="setColor(item.status)">{{ setStatus(item.status) }}</v-chip>
                    </template>

                    <template v-slot:[`item.note`]="{ item }">
                        <v-flex d-flex align-center>
                            <v-text-field v-model="item.note" hide-details></v-text-field>
                            <v-btn class="mb-3" :icon=true variant="text" @click="setNote(item)">
                                <v-icon>mdi-content-save-outline </v-icon>
                            </v-btn>
                        </v-flex>
                    </template>

                    <template v-slot:[`item.focus`]="{ item }">
                        <v-responsive align="center">
                            <v-btn :disabled="item.status == 'A'" variant="text" @click="getRigheOrdine(item)"
                                :icon=true>
                                <v-icon>
                                    mdi-chevron-right
                                </v-icon>
                            </v-btn>
                        </v-responsive>
                    </template>
                </v-data-table>

                <v-btn color="seritec" class="mb-4 white--text" @click="home()">TORNA ALLA HOME</v-btn>
            </v-tab-item>
            <!-- FOCUS SULLE RIGHE DELL'ORDINE DI SELECTED -->
            <v-tab-item>
                <h2 align="center">Ordine selezionato: {{ selectedOrder.documentNo }}</h2>

                <v-btn color="seritec  white--text" class="mb-3"
                    @click="downloadOrder(selectedOrder.c_order_id)">
                        <v-icon>mdi-download</v-icon>
                    Scarica pdf ordine
                </v-btn>

                <v-data-table item-key="fct_project_id" v-model="selectedOl" show-select :items="orderlines" :headers="headersOl" show-expand>
                    <template v-slot:[`item.scadenza`]="{ item }">
                        <div v-if="item.scadenza">
                            {{ dateToItalianFormat(item.scadenza) }}
                        </div>

                    </template>

                    <template v-slot:[`item.statusPrj`]="{ item }">
                        <v-chip :color="setColor(item.statusPrj)">{{ setstatusPrj(item.statusPrj) }}</v-chip>
                    </template>

                    <template v-slot:[`item.tecnici`]="{ item }">
                        <v-chip class="mt-1" v-for="tecnico in item.tecnici" :key="tecnico.name">{{ tecnico.name
                            }}</v-chip>
                    </template>

                    <template v-slot:[`item.dateProgrammed`]="{ item }">
                        <div v-if="item.dateProgrammed">
                            {{ dateToItalianFormat(item.dateProgrammed) }}
                        </div>
                    </template>

                    <template v-slot:[`item.complete`]="{ item }">
                        <v-btn v-if="item.fc_scheduledjob_id" icon @click="setCompletedDialog([item])"
                        :disabled="!['N', 'S'].includes(item.statusPrj)">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>

                        <v-btn v-if="!item.fc_scheduledjob_id" icon @click="setOrderLineCompleted([item])"
                            :disabled="!['N', 'S'].includes(item.statusPrj)">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:[`item.delete`]="{ item }">
                        <v-btn :disabled="!['C', 'S'].includes(item.statusPrj)"
                        icon @click="deleteScheduledJob(item)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:expanded-item = "{headers, item}">
                        <td :colspan="headers.length">
                            <v-flex d-flex align-center>
                            <v-text-field label="Note" v-model="item.note" hide-details outlined dense></v-text-field>
                            <v-btn class="mb-3" icon @click="setOrderLineNote(item)">
                                <v-icon>mdi-content-save-outline </v-icon>
                            </v-btn>
                        </v-flex>
                        </td>
                        
                    </template>
                </v-data-table>

                <v-dialog v-model="completedDialog" persistent>
                    <v-card density='confortable' >
                        <v-card-title><b>Completa attività effettuata</b></v-card-title>
                        <v-card-text>Completa i campi sottostanti per rendicontare le spese sostenute</v-card-text>

                        <!-- Chiesti da francesca solo ore straordinarie e data -->
                        <div class="ml-5 mr-5" width="500">    
                            <v-row>
                                <v-col>
                                    <DatePickerText v-model="completedDateStart" label="Data inizio attività"></DatePickerText>
                                </v-col>
                                <v-col>
                                    <TimePickerText v-model="completedDateStart_hhmm" format="24hr" 
                                    label="Ora inizio attività"></TimePickerText>
                                </v-col>
                            </v-row>   
                            <v-row>
                                <v-col>
                                    <DatePickerText v-model="completedDateStop" label="Data fine attività"></DatePickerText>
                                </v-col>
                                <v-col>
                                    <TimePickerText v-model="completedDateStop_hhmm" format="24hr"
                                    label="Ora fine attività"></TimePickerText>
                                </v-col>
                            </v-row>                     
                            <v-row>
                                <TimePickerText v-model="hours" format="24hr" label="Ore straordinarie"></TimePickerText>
                            </v-row>  
                            <v-row>
                                <v-checkbox v-model="isCompleted" class="ml-3" label="Attività completata"></v-checkbox>
                            </v-row>
                        </div>

                        <v-row>
                            <v-col align="center">
                                <v-btn color="red" @click="closeCompletedDialog()">Chiudi finestra</v-btn>
                            </v-col>
                            <v-col align="center">
                                <v-btn class="white--text" color="seritec"
                                    @click="createCompletedJob(olToBeCompleted)">Completa
                                    Attività</v-btn>
                            </v-col>
                        </v-row>

                    </v-card>
                </v-dialog>

                <v-flex d-flex>
                    <v-btn color="seritec" class="mb-4 white--text" @click="goBack()">Indietro</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="checkSelectedProgrammate" color="yellow" style="margin: 0px 5px 0px 5px"
                        @click="openCreateActivity()">Crea Attività Programmate</v-btn>
                        <v-btn :disabled="completeBtnDisabled" color="primary" style="margin: 0px 5px 0px 5px"
                        @click="completeActivity()">Completa Attività</v-btn>
                </v-flex>
                <Event ref="event"></Event>
                <br>
            </v-tab-item>
            <!-- :users= "this.technicians.map((x) => x.ad_user_id)"-->
            <v-dialog v-model="createActivity" persistent>
                <v-card>
                    <Calendar 
                        :ad_user_id= "this.$session.get('ad_user_id')"
                        :users="this.technicians.filter((x) => x.ad_user_id == this.$session.get('ad_user_id')).map((x) => x.ad_user_id)"

                        :bpname=this.selectedOrder.ragioneSociale
                        :bpvalue=this.selectedOrder.bpvalue
                        :c_bpartner_id=this.selectedOrder.c_bpartner_id
                        :projects="this.selectedOl.map((x) => ({fct_project_id: x.fct_project_id}))"
                    >
                    </Calendar>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="seritec" class="white--text" @click="closeCreateActivity()">Chiudi finestra</v-btn>
                    </v-card-actions>
                </v-card>
                


            </v-dialog>

        </v-tabs>

        <Event ref="event"></Event>
        <confirm-dialog ref="confirm"></confirm-dialog>
    </v-container>
</template>

<script>
/* eslint-disable */
import MixingCommonComp from '../mixin/MixingCommonComp';
import DataPicker from '../components/DataPicker.vue';
import Event from '../components/Event.vue';
import ConfirmDialog from '../components/ConfirmDialog.vue';
import Calendar from "../components/Calendar.vue";
import DatePickerText from "../components/DatePickerText.vue";
import TimePickerText from "../components/TimePickerText.vue";

export default {
    mixins: [MixingCommonComp],
    components: { DataPicker, Event, ConfirmDialog, Calendar, DatePickerText, TimePickerText },
    data() {
        return {

            tab: 0,
            ricercaCliente: "",
            c_bpartner_id: null,
            dataOrdineFrom: null,
            dataOrdineTo: null,
            stato: ["N", "S", "C"],
            documentNo: null,
            orders: [],
            bpartners: [],
            ad_user_id: null,
            technicians: [],
            headersOdvs: [
                { value: "documentNo", text: "Ordine", width: "10%" },
                { value: "dataOrdine", text: "Data Ordine", width: "10%" },
                //{ value: "dataPromessa", text: "Data Promessa", width: "10%" },
                { value: "poReference", text: "Numero ordine Cliente", width: "10%" },
                { value: "ragioneSociale", text: "Ragione sociale", width: "10%" },
                { value: "status", text: "Stato", width: "10%" },
                { value: "tecnici", text: "Assegnato a", width: "10%" },
                { value: "note", text: "Note", width: "25%" },
                { value: "focus", text: "Linee", width: "5%" }
            ],
            orderlines: [],
            headersOl: [
                { value: "line", text: "Numero riga", width: "10%" },
                { value: "description", text: "Descrizione", width: "40%" },
                { value: "numerofabbrica", text: "Num Fabbrica", width: "10%" },
                { value: "matricolainail", text: "Matricola", width: "10%" },
                { value: "scadenza", text: "Scadenza", width: "10%" },
                { value: "statusPrj", text: "Stato", width: "10%" },
                { value: "tecnici", text: "Tecnici associati", width: "10%" },
                { value: "note", text: "Note", width: "10%" },
                { value: "dateProgrammed", text: "Data programmazione", width: "10%" },
                { value: "complete", text: "Completa", width: "10%" },
                { value: "delete", text: "Torna a Da pianificare", width: "10%%"}
            ],
            selectedOrder: {},
            selectedOl: [],
            status: [{ value: 'N', stato: "Da pianificare" },
            { value: 'S', stato: "In corso" },
            { value: 'C', stato: "Da fatturare" },
            //{ value: 'A', stato: "Preventivo"},
            { value: 'I', stato: "Fatturato" }
            ],
            odvs: [],
            capiCommessa: [],
            roles: [],
            completedDialog: false,


            // variabili relative a completamento attività già schedulata
            //rimossi km, amount,notes: per francesca basta solamente data e ore straordinarie
            /*km: null,
            amount: null,
            notes: null, */
            hours: "00:00",
            completedDateStart: new Date().toISOString().substring(0,10),
            completedDateStop: new Date().toISOString().substring(0,10),

            completedDateStart_hhmm: "00:00",
            completedDateStop_hhmm: "00:00",
            
            isCompleted: true,
            //
            olsToBeCompleted: [],

            createEvent: null,
            createStart: new Date().toISOString().substring(0, 10),
            createActivity: false,

            downloadLink: null,
        }
    },
    methods: {
        home() {
            this.$router.push({ path: "/" });
        },

        goBack() {
            if (this.selectedOrder.length != 0) {
                this.selectedOrder = {};
            }
            this.tab = 0;
            this.selectedOl = [];
        },

        async getRigheOrdine(item) {
            this.tab = 1;
            this.selectedOrder = item;
            await this.getOrderlinesFromOrder(this.selectedOrder);
        },

        async getOrders() {
            this.showLoadingDialog(true, "Caricamento in corso...");
            let parameters = {};
            parameters.data = {
                c_bpartner_id: this.c_bpartner_id, dateOrdered: this.dataOrdineFrom,
                orderCustomerDate: this.dataOrdineTo,
                documentno: this.documentNo, ad_user_id: this.ad_user_id,
                status: this.stato.join(',')
            };

            parameters.idempiereRestPath = "vecos/getOrders";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.orders = response.data.orders;
                for (let i in response.data.orders) {
                    this.odvs.push(response.data.orders[i].documentNo)
                }
                this.showLoadingDialog(false);
                //console.log(this.orders);
            })
        },

        async getOrderlinesFromOrder(item) {
            this.showLoadingDialog(true, "Caricamento...")
            let parameters = {};
            parameters.data = { c_order_id: item.c_order_id };
            parameters.idempiereRestPath = "vecos/getOrderlinesFromOrder";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.orderlines = response.data.orderlines;
                console.log(this.orderlines);
                this.showLoadingDialog(false)
            })
        },

        async getTechnicians() {
            let parameters = {};
            parameters.data = {};
            parameters.idempiereRestPath = "vecos/getTechnicians";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {

                this.technicians = response.data.technicians;
                for (let i in this.technicians) {
                    this.capiCommessa.push(this.technicians[i]);
                }
                this.setTechnician;
            })
        },

        openCreateActivity() {
           
            this.createActivity = true;
        },
        async closeCreateActivity(){
            this.createActivity = false;
            this.projects = [];
            await this.getOrderlinesFromOrder(this.selectedOrder);
        },

        setStatus(item) {
            if (item == 'N')
                return 'Da pianificare';
            else if (item == 'S') {
                return 'In corso'
            }
            else if (item == 'C') {
                return 'Da fatturare'
            }
            else if (item == 'I') {
                return 'Fatturata';
            }
            else if (item == 'A') {
                return 'Preventivo';
            }
        },
        setstatusPrj(item) {
            if (item == 'N')
                return 'Da pianificare';
            else if (item == 'S') {
                return 'In corso'
            }
            else if (item == 'C') {
                return 'Da fatturare';
            }
            else if (item == 'I') {
                return 'Fatturata';
            }
        },
        setColor(item) {
            if (item == 'N') {
                return "red"
            }
            if (item == 'S' || item == 'P') {
                return 'yellow'
            }
            if (item == 'I') {
                return 'green'
            }
            if (item == 'C') {
                return 'blue lighten-2'
            }
            if (item == 'A') {
                return 'brown lighten-2'
            }
        },

        setNote(item) {
            let parameters = {};
            parameters.data = { note: item.note, c_order_id: item.c_order_id };
            parameters.idempiereRestPath = "vecos/setNote";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                console.log(response);
                if (response.data.result == "OK") {
                    this.showInfoDialog("Nuova nota inserita correttamente")
                }
                else {
                    this.showErrorDialog("Impossibile aggiungere la nuova nota")
                }
            })
        },

        async setOrderLineCompleted(items) {
            if (!await this.$refs.confirm.open("Completa", "Vuoi completare questa riga?")) {
                return;
            }

            for(let item of items) {
                let parameters = {};
                // cambiato parametro: da c_orderline_id a fct_project_id, in modo tale da fatturare solo una 
                // riga dell'ordine con quel singolo project lì
                parameters.data = { fct_project_id: item.fct_project_id, c_orderline_id: item.c_orderline_id };
                parameters.idempiereRestPath = "vecos/setOrderLineCompleted";
                this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                    console.log(response)
                    if (response.data.result == "OK") {
                        item.statusPrj = "C";
                        this.getOrderlinesFromOrder(item);
                    }
                    else {
                        this.showErrorDialog(response.data.message);
                    }
                })
            }
        },

        async getUserRoles() {
            let ad_user_id = this.$session.get("ad_user_id")
            let parameters = {};
            parameters.data = { ad_user_id: ad_user_id };
            parameters.idempiereRestPath = "vecos/getUserRoles";
            await this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                this.roles = response.data.roles;
            })
        },

        checkIfAdmin() {
            for (let role of this.roles) {
                if (role.ad_role_id == 1000017 || role.ad_role_id == 1000025 || role.ad_role_id == 1000034) {
                    this.status.push({ value: 'A', stato: "Preventivo" });
                }
            }
        },

        setCompletedDialog(items) {
            this.completedDialog = true;
            this.olsToBeCompleted = items;

        },

        async createCompletedJob() {
            this.showLoadingDialog(true, "Caricamento in corso...")
            for(let olToBeCompleted of this.olsToBeCompleted){
                console.log("createCompletedJob")
                let parameters = {};
                parameters.data = {
                    fc_scheduledjob_id: olToBeCompleted.fc_scheduledjob_id, 

                    dateStart: new Date(this.completedDateStart + " " + this.completedDateStart_hhmm),
                    dateStop: new Date(this.completedDateStop + " " + this.completedDateStop_hhmm),

                    oreStraordinarie: new Date(new Date().toISOString().substring(0,10) + " " + this.hours), 
                    isLavoroTerminato: this.isCompleted, 
                    
                    c_orderline_id: olToBeCompleted.c_orderline_id
                };
                parameters.idempiereRestPath = "vecos/createCompletedJob";
                let response = await this.$store.dispatch("callIdempiereRest", parameters);
                console.log(response);
                if (response.data.result == 'OK') {
                    let index = this.orderlines.findIndex((x) => x == olToBeCompleted);
                    this.orderlines[index].statusPrj = 'C';
                }
                    //this.getOrderlinesFromOrder(item.c_order_id);

            }

            this.closeCompletedDialog();
            this.showLoadingDialog(false);
            this.showInfoDialog("Attività effettuata inserita correttamente.");
        },

        completeActivity(){
            if(this.selectedOl[0].statusPrj == 'S'){
                this.setCompletedDialog(this.selectedOl);
            }else{
                this.setOrderLineCompleted(this.selectedOl);
            }
        },

        closeCompletedDialog() {
            this.completedDialog = false;
            /*this.notes = null;
            this.km = 0;
            this.amount = 0; */
            this.hours = "00:00";
            this.completedDateStart = new Date().toISOString().substring(0, 10);
            this.completedDateStop = new Date().toISOString().substring(0,10);
            this.completedDateStart_hhmm = "00:00";
            this.completedDateStop_hhmm = "00:00";
            this.isCompleted = true;
        },

        downloadOrder(c_order_id) {
            this.showLoadingDialog(true, "Download in corso...");
            let parameters = {};
            parameters.data = { c_order_id: c_order_id };
            parameters.idempiereRestPath = "seritec/downloadOrder";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                if (response.data.result == "OK") {
                    this.showLoadingDialog(false);
                    this.downloadLink = response.data;
                    console.log(this.downloadLink)
                    this.downloadFile();
                    this.downloadLink = null; //aggiunta nella nuova pagina
                } else {
                    this.showErrorDialog(response.data.message);
                }
            });
        },
        downloadFile() {
            const linkSource = "data:application/pdf;base64," + this.downloadLink.data;
            const downloadLink = document.createElement("a");
            const fileName = this.downloadLink.documentno + ".pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },

        async deleteScheduledJob(item){
            console.log(item);
            if (!await this.$refs.confirm.open("Elimina attività programmata", 
            "Vuoi ritornare allo status 'Da completare' per questa riga dell'ordine?")) {
                return;
            } 
            
            let parameters = {};
            parameters.data = {fc_scheduledjob_id: item.fc_scheduledjob_id, fct_project_id: item.fct_project_id};
            parameters.idempiereRestPath = "vecos/backToBePlanned";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                
                console.log(response);
                if(response.data.result == "OK"){
                    item.statusPrj = 'N';
                }
            });
        },
        setOrderLineNote(orderLine){
            let parameters = {};
            parameters.data = { note: orderLine.note, c_orderline_id: orderLine.c_orderline_id };
            parameters.idempiereRestPath = "vecos/setOrderLineNote";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                console.log(response);
                if (response.data.result == "OK") {
                    this.showInfoDialog("Nuova nota inserita correttamente")
                }
                else {
                    this.showErrorDialog("Impossibile aggiungere la nuova nota")
                }
            })
        }
    },

    async mounted() {

        this.showLoadingDialog(true, "Caricamento...");
        await this.getUserRoles();
        this.checkIfAdmin();

        await this.getTechnicians();
        //await this.getOrders();
        this.showLoadingDialog(false);
    },

    computed: {
        checkSelectedProgrammate() {
            if (this.selectedOl.length == 0) {
                return true;
            }
            for (let i in this.selectedOl) {
                if (this.selectedOl[i].statusPrj == 'C' || this.selectedOl[i].statusPrj == 'I') {
                    return true;
                }
            }
            return false;
        },
        checkSelectedEffettuate() {
            if (this.selectedOl.length == 0) {
                return true;
            }
            for (let i in this.selectedOl) {
                if (this.selectedOl[i].statusPrj != 'S') {
                    return true;
                }
            }
            return false;
        },
        setTechnician() {
            if (this.technicians.length == 1) {
                this.ad_user_id = this.technicians[0].ad_user_id;
                return true;
            }
            return false;
        },
        completeBtnDisabled(){
            if(this.selectedOl == null || this.selectedOl.length <= 0){
                return true;
            }

            let statusPrj = null;

            for(let project of this.selectedOl){
                if(!['N', 'S'].includes(project.statusPrj)){
                    return true;
                }

                if(statusPrj == null){
                    statusPrj = project.statusPrj;
                }

                if(statusPrj != project.statusPrj){
                    return true;
                }
            }
            return false;
        }
    },
    watch: {
        ricercaCliente: function () {
            if (
                this.ricercaCliente == null ||
                this.ricercaCliente == "" ||
                this.ricercaCliente.length < 3
            )
                return;
            let parameters = {};
            parameters.data = { name: this.ricercaCliente };
            parameters.idempiereRestPath = "effecitech/cercaCliente";
            this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
                if (response.data.result == "OK") {
                    this.bpartners = response.data.listaClienti;
                    console.log(this.bpartners);
                }
            });
        }
    }
}
</script>