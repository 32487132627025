<template>
  <span>
    {{ text }}
  </span>
</template>

<script>
/*eslint-disable */
export default {
  name: "TextTable",
  props: {
    value: { type: String },
    column: { type: Object },
    language: { type: String, default: "it_IT" },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getListOptions();
  },
  methods: {
    getListOptions() {
      let parameters = {};
      parameters.data = { ad_column_id: this.column.AD_Column_ID };
      parameters.idempiereRestPath = "window/getListOptions";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.options = response.data.options;
      });
    },
  },
  computed: {
    record_id: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    text: function () {
      if (!this.record_id) return "";
      let element = this.options.find((item) => {
        return item.value == this.record_id;
      });
      if (!element) return "";
      return element.trl[this.language];
    },
  },
  watch: {
    value: function () {
      this.getListOptions();
    },
  },
};
</script>

<style>
</style>