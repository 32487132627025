<template>
  <span>
    <v-btn
      :text="text"
      @click="openDialog"
      :icon="icon"
      :disabled="attachmentDisabled"
    >
      <v-icon v-if="!hideIcon">{{ iconClass }}</v-icon>
      <span v-if="!hideText">{{ label }}</span>
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="50%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-card-title>Allegati</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="9">
              <v-select
                label="Allegato"
                :items="attachments"
                item-key="index"
                item-text="name"
                outlined
                dense
                return-object
                v-model="attachment"
              ></v-select> </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
              class="mb-2"
                :accept="accept"
                hide-input
                truncate-length="0"
                @change="uploadAttachment"
                hide-details
                dense
                prepend-icon="mdi-paperclip-plus"
              ></v-file-input>
            </v-col>
            <v-col cols="1">
              <v-btn icon :disabled="!attachment" @click="downloadAttachment"
                ><v-icon>mdi-download</v-icon></v-btn
              >
            </v-col>
            <v-col cols="1">
              <v-btn icon :disabled="!attachment" @click="deleteAttachment"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <span v-if="attachment">
            <img v-if="imgSrc" :src="imgSrc" width="100%" height="100%" />
            <p v-else>Anteprima non disponibile</p>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="closeDialog">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
  </span>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog.vue";

/*eslint-disable*/
export default {
  components: { ConfirmDialog },
  props: {
    value: { type: Number, default: 0 },
    tableName: { type: String, default: "" },
    text: { type: Boolean, default: false },
    icon: { type: Boolean, default: false },
    hideText: { type: Boolean, default: false },
    hideIcon: { type: Boolean, default: false },
    iconClass: { type: String, default: "mdi-paperclip" },
    label: { type: String, default: "Allegati" },
    accept: { type: String, default: "" },
  },
  data() {
    return {
      dialog: false,
      attachments: [],
      attachment: null,
    };
  },
  methods: {
    async getAttachment() {
      let parameters = {};
      parameters.data = { tableName: this.tableName, record_id: this.value };
      parameters.idempiereRestPath = "attachment/getAttachment";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          if (response.data.result == "OK") {
            this.attachments = response.data.attachments;
            /*this.attachments = this.attachments.filter((item) => {
            return /image\/.{1,}/.test(item.contentType);
          });*/
          } else {
            throw response.data.message;
          }
        });
    },
    async openDialog() {
      this.attachment = null;
      await this.getAttachment();
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    uploadAttachment(value) {
      var reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        let fileContent = reader.result.toString().replace(/^data:(.*,)?/, "");

        let parameters = {};
        parameters.data = {
          tableName: this.tableName,
          record_id: this.value,
          filename: value.name,
          fileContent: fileContent,
        };
        parameters.idempiereRestPath = "attachment/uploadAttachment";
        this.$store
          .dispatch("callIdempiereRest", parameters)
          .then((response) => {
            this.getAttachment();
          });
      };
    },
    async deleteAttachment() {
      if (
        !(await this.$refs.confirm.open(
          "Elimina",
          "Vuoi eliminare questo allegato?"
        ))
      )
        return;
      let parameters = {};
      parameters.data = {
        tableName: this.tableName,
        record_id: this.value,
        index: this.attachment.index,
      };
      parameters.idempiereRestPath = "attachment/deleteAttachment";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        if (response.data.result == "OK") {
          this.attachment = null;
        }
        this.getAttachment();
      });
    },
    downloadAttachment(){
      let link = document.createElement("a");
      link.download = this.attachment.name;
      link.href = this.getAttachmentDataUrl(this.attachment);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getAttachmentDataUrl(attachment){
      let url = "data:" + attachment.contentType;
      url += "; base64, " + attachment.data;
      return url;
    }
  },
  watch: {
    value: function () {
      this.getAttachment();
    },
    ad_table_id: function () {
      this.getAttachment();
    },
  },
  computed: {
    imgSrc() {
      if (!this.attachment) return "";
      if (!/image\/.{1,}/.test(this.attachment.contentType)) return "";
      return this.getAttachmentDataUrl(this.attachment);
    },
    attachmentDisabled() {
      if (this.value <= 0) return true;
      if (!this.tableName) return true;
      return false;
    },
  },
};
</script>

<style>
</style>