<template>
  <span>
    <v-dialog v-model="dialog" persistent max-width="50%">
      <v-card>
        <v-card-title>Selezione Tipo Evento</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-btn @click="openDialogEvent" width="100%">Pianificazione Attività</v-btn>
            </v-col>
            <v-col>
              <v-btn @click="openDialogEventRequest" width="100%">Ferie/Permessi</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="close">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Event ref="event"></Event>
    <EventRequest ref="eventRequest"></EventRequest>
  </span>
</template>

<script>
import MixingCommonComp from "../mixin/MixingCommonComp";

import Event from "./Event.vue";
import EventRequest from "./EventRequest.vue";

export default {
  mixins: [MixingCommonComp],
  components: { Event, EventRequest },
  data() {
    return {
      dialog: false,
      event: null,
      resolve: null,
      reject: null,
    };
  },
  methods: {
    open(event) {
      this.dialog = true;
      this.event = event;

      return new Promise(
        (resolve) => {
          this.resolve = resolve;
        },
        (reject) => {
          this.reject = reject;
        }
      );
    },
    close() {
      this.dialog = false;
      this.resolve();
    },
    openDialogEvent() {
      this.dialog = false;
      this.$refs.event.open(this.event).then(
        (resolve) => {
          this.resolve(resolve);
        },
        (reject) => {
          this.reject(reject);
        }
      );
    },
    openDialogEventRequest() {
      this.dialog = false;
      this.$refs.eventRequest.open(this.event).then(
        (resolve) => {
          this.resolve(resolve);
        },
        (reject) => {
          this.reject(reject);
        }
      );
    },
  },
};
</script>

<style>
</style>